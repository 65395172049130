<div class="container-fluid">
    <div class="row no-gutter">
        <div class="col-md-3 d-none d-md-flex">
        </div>
        
        <div class="col-md-6 ">
            <div class="login d-flex align-items-center py-5">
                <div class="container bg-light pb-3 rounded" style="width: 50%;">
                    <div class="logo mb-5">
                        <a routerLink="/">
                            <img src="/assets/images/logo_voguel.png" alt="Voguel Consulting" title="logo"
                                class="img-fluid" style="width:25%;float:left; clear: both; " />
                        </a>
                    </div>
                    <div class="row" *ngIf="Validation == false">
                        <div class="col-lg-10 col-xl-10 mx-auto">
                            <h3 class="display-4 text-center"
                                style="font: 500 18px/26px Inter var,Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif">
                                Account confirmation</h3>
                            <h4 class="body-2 m-0 ng-tns-c136-105" style="color: rgba(0, 0, 0, .38);">Enter your
                                validation code.</h4>
                            <br>
                            <form id="code-verification-form">
                                <div class="form-group mb-3">
                                    <input id="codeVerification" type="text" placeholder="code validation" name="email"
                                        required autofocus="" class="form-control border-0 shadow-sm px-4" (change)="verify()" required>
                                </div>
                                <div class="form-error-msg" hidden>
                                    <span class="error-text">Invalide Code</span>
                                </div>
                                <!-- <button type="submit"
                                    class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm" id="Btnverification">Send
                                    code</button> -->
                            </form>
                        </div>
                        
                    </div>
                    <div *ngIf="recaptch_checked == false" class="from-group ml-5">
                        <div class="g-recaptcha" data-sitekey="12345" data-size="invisible" data-badge="inline">
                          <div class="captcha">
                            <re-captcha (resolved)="resolved($event)" siteKey={{keyrecaptcha}} required></re-captcha>
                            <div class="form-error-msg" hidden>
                              <span class="error-text">This field is required</span>
                            </div>
                          </div>
                          <div [hidden]="!captcha">
                          </div>
                        </div>
            
                      </div>
                    <div class="row" *ngIf="Validation == true && recaptch_checked ==true">
                        <div class="col-lg-10 col-xl-10 mx-auto">
                            <h3 class="display-4 text-center"
                                style="font: 500 18px/26px Inter var,Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif">
                                Account confirmation</h3>
                                <div class="alert alert-custom alert-light-danger fade show mb-10" role="alert">
                                    <div class="alert-icon">
                                        <span class="svg-icon svg-icon-3x svg-icon-danger">
                                            <!--begin::Svg Icon | path:/metronic/theme/html/demo9/dist/assets/media/svg/icons/Code/Info-circle.svg-->
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                    <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"></circle>
                                                    <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1"></rect>
                                                    <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1"></rect>
                                                </g>
                                            </svg>
                                            <!--end::Svg Icon-->
                                        </span>
                                    </div>
                                    <div class="alert-text font-weight-bold">Configure your password. You will need it to get locked in of the system!
                                    <br>Password must contain at least: 1 Uppercase letter, 1 Lowercase letter, Numbers and min 8 max 20 char long</div>
                                    <div class="alert-close">
                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">
                                                <i class="ki ki-close"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            <br>
                            <form form #passwordForm="ngForm" (ngSubmit)="submitNewPasswordForm(passwordForm)" id="password-form">
                                <div class="form-group mb-3">
                                    <input id="passwordconfirme" type="password" placeholder="Password" name="newPassword"
                                        required autofocus="" class="form-control border-0 shadow-sm px-4" required>
                                        <div class="form-error-msg" hidden>
                                            <span class="error-text">Invalide Field</span>
                                        </div>
                                </div>
                                <div class="form-group mb-3">
                                    <input id="passwordconfirme" type="password" placeholder="Password confirmation" name="confirmNewPassword"
                                        required autofocus="" class="form-control border-0 shadow-sm px-4" required>
                                        <div class="form-error-msg" hidden>
                                            <span class="error-text">Invalide Field</span>
                                        </div>
                                </div>
                                <div class="form-error-msg" hidden>
                                    <span class="error-text">Invalide Code</span>
                                </div>
                                <button type="submit"
                                    class="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm" id="Btnverification" name="submitpasswordbtn" >Change password</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>