import {Address} from './address';
import {Privilege} from './privilege';
import {Company} from './company';


export class User {
  /** User Model
   *
   * **privilege** attribute for access & middleware in backend side (RH, Employee, Admin)
   * **is_superuser** attribute for users who can manage and attribute Admin privilege
   *
   **/
  _id?: string;
  company_id?: Company;
  cin?: string;

  //username: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  profil_img: string;
  position?:string;
  lang?:string;  
  genre?:string;  
  is_superuser?: boolean;
  is_active?: boolean;
  privilege_id: Privilege;
  address?: Address;
  country?:string;
  password?: string;
  createdAt?: Date;

  constructor(
    first_name: string,
    last_name: string,
    email: string,
    privilege_id: Privilege,
    password: string,
    phone_number: string,
    cin: string=""

  ) {
    //this.username = username;
    this.email = email;
    this.first_name = first_name;
    this.last_name = last_name;
    this.phone_number = phone_number;
    this.privilege_id = privilege_id;
    this.password = password;
    this.cin=cin
  }
}

export class UserRequest {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  phone: string;
  privilege_id: string;
  password: string;
  address_number: string;
  address_street: string;
  address_postal_code: string;
  address_city: string;
  cin:string;
  profil_img: string;
  position:string;
  lang:string;
  genre:string;
  country:string;


}

