<div class="se-pre-con"></div>
<section>
  <div class="wrapper-content-footer">
    <div>
      <app-navbar></app-navbar>
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</section>

<button (click)="topFunction()" class="bg-primary" id="movetop" title="Go to top">
  <span class="fa fa-angle-up"></span>
</button>


