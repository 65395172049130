import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services//auth.service';
import { StorageService } from '../services/storage.service';
import { catchError } from 'rxjs/operators';


@Injectable()
export class HttpconfigInterceptor implements HttpInterceptor {

  constructor(private router: Router, private authService: AuthService, private storageService: StorageService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | Observable<any>  {
        const token = this.storageService.read(StorageService.USER_TOKEN_KEY)
        if (token) {
            request = request.clone({
                headers: request.headers.set('Authorization', 'Bearer ' + token),
            });
        }
        return next.handle(request).pipe(
            catchError(error => {
                if (error.status === 403) {
                    this.router.navigate(['/auth/login']);
                    this.storageService.removeAll();
                    this.authService.user = null;
                }
                return throwError(error);
            })
        );
    }

}
