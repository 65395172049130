<div class="bodre" hidden="true">
    <div class="loader" hidden="true">
        <style>
            /* Safari */
            @-webkit-keyframes spin {
                0% {
                    -webkit-transform: rotate(0deg);
                }

                100% {
                    -webkit-transform: rotate(360deg);
                }
            }

            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        </style>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-6 ">
            <div class="logo mb-5">
                <a routerLink="/">
                    <img src="{{imageUrl}}" alt="Voguel Consulting" title="logo" class="img-fluid logo-image"
                        style="width:90%;float: revert;margin-top: 30%; text-align:center; background-color: transparent; " />
                </a>

            </div>
        </div>

        <div class="col-md-6 " style="background: #ffffff;">
            <!-- Multi langue-->
            <div class="user-dropdown-details align-items-right "
                style="text-align: end; margin-top: 1rem; margin-right: 1rem; cursor: pointer;">
                <div class="profile_details_left">
                    <ul class="nofitications-dropdown">
                        <li class="dropdown">
                            <!-- <a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false" disable> -->
                            <a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false" disable>
                                <i style="opacity: 100% !important;"> <img  *ngIf="selectedLang == 'fr'" src="/assets/images/fr.svg" alt="">
                                    <img  *ngIf="selectedLang == 'en'" src="/assets/images/uk.svg" alt="">
                                </i>
                            </a>
                            <ul class="dropdown-menu">

                                <li>
                                    <div class="flag_img" (click)="lang('fr')"><img src="/assets/images/fr.svg"
                                            alt="fr"> </div>

                                </li>
                                <li class="odd">
                                    <div class="flag_img" (click)="lang('en')">
                                        <img src="/assets/images/uk.svg" alt="en">
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>


            <div class="login d-flex align-items-center py-5" style="background-color: #ffffff;">



                <!-- <div class="container  rounded" > -->

                <div class="col-lg-12 col-xl-7 mx-auto" style="margin-bottom: 20px; margin-top: -10rem;">
                    <h1 *ngIf="selectedLang == 'en'" class="display-4 text-center logintext">Reset password</h1>
                    <h1 *ngIf="selectedLang == 'fr'" class="display-4 text-center logintext">Réinitialiser le mot de
                        passe</h1>
                    <h3 class="mb-4 text-center logintext2">TimeSheet</h3>
                    <br><br><br>
                    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                        <div *ngIf="step1" class="form-group mb-3">
                            <label><h3><strong>Email</strong></h3></label>
                            <input input type="text" formControlName="email" placeholder="Email"
                                class="form-control rounded-pill border-3  px-4"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                            <div *ngIf=" f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">{{'Error_field' | translate}}</div>
                                <div *ngIf="f.email.errors.email">{{'Error_mail_format'|translate}}</div>
                            </div>
                            <!-- REcaptcha blooc-->
                            <div *ngIf="activaterecaptcha1" class="from-group ml-1">
                                <div class="g-recaptcha" data-sitekey="12345" data-size="invisible" data-badge="inline">
                                    <div class="captcha">
                                        <script
                                            src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
                                            async defer></script>
                                        <re-captcha (resolved)="resolved($event)" siteKey={{keyrecaptcha}}
                                            required></re-captcha>


                                        <div class="form-error-msg" hidden>
                                            <span class="error-text">{{'Error_field' | translate}}</span>
                                        </div>
                                    </div>
                                    <div [hidden]="!captcha">
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div *ngIf="step2" class="form-group mb-3">
                            <label *ngIf="selectedLang == 'en'">
                                <h2>Check your mailbox and follow the instructions for resetting your password.</h2>
                                <h2>Thank you for your visit.</h2>
                            </label>
                            <label *ngIf="selectedLang == 'fr'">
                                <h2>Vérifier votre boite mail et veuillez suivre les instructions pour la réinitialisation de votre mot de passe.</h2>
                                <h2>Merci pour votre visite.</h2>
                            </label>
                            <!-- <div style="text-align: center;"><label style="text-align: center;">
                                    <h2><strong>Code:</strong></h2>
                                    <h1><strong>{{code}}</strong></h1>
                                </label><br></div> -->
                        </div>
                        <div *ngIf="step1">
                            <button type="submit" class="btn btn-primary btn-style mt-4 float-right"
                                style="margin-right: 5px; border-color:#cbd4d8 ; background-color: #87D0F3;">{{'Btn_submit'
                                | translate}}</button>

                            <button routerLink="/" type="button" class="btn btn-primary btn-style mt-4 float-left"
                                style="background-color: #cbd4d8 ;border-color: #cbd4d8; color: #FFFF;">{{'Btn_cancel' | translate}}</button>
                        </div>
                    </form>
                    <div *ngIf="step3">
                        <!-- HEADER OF PAGE -->
                        <div class="alert alert-custom alert-light-danger fade show mb-10" role="alert">
                            <div class="alert-icon">
                                <span class="svg-icon svg-icon-3x svg-icon-danger">
                                    <!--begin::Svg Icon | path:/metronic/theme/html/demo9/dist/assets/media/svg/icons/Code/Info-circle.svg-->
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"></circle>
                                            <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1"></rect>
                                            <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1"></rect>
                                        </g>
                                    </svg>
                                    <!--end::Svg Icon-->
                                </span>
                            </div>
                            <div class="alert-text font-weight-bold"><br>{{'shared_11' |translate }}</div>
                            <div class="alert-close">
                                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">
                                        <i class="ki ki-close"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <form [formGroup]="changingForm" id="password-form">
                            <div class="form-group mb-3">
                                <label>
                                    <h4>{{'Form_40' | translate}}</h4>
                                </label>
                                <input formControlName="password" type="password"
                                    [ngClass]="{ 'is-invalid': submitted2 && g.password.errors }"
                                    class="form-control rounded-pill border-3  px-4" required>
                                <div *ngIf=" g.password.errors" class="invalid-feedback">
                                    <div *ngIf="g.password.errors.required">{{'Error_field' | translate}}</div>
                                    <div *ngIf="g.password.errors.minlength">{{'Error_pwd_1' | translate}}</div>
                                    <div *ngIf="g.password.errors.maxlength">{{'Error_pwd_2' | translate}}</div>
                                    <div *ngIf="g.password.errors.pattern">{{'Error_pwd_3' | translate}}</div>

                                </div>

                            </div>
                            <div class="form-group mb-3">
                                <label>
                                    <h4>{{'shared_5' | translate}} {{'Form_40' | translate}}</h4>
                                </label>
                                <input type="password" formControlName="confirmat"
                                    [ngClass]="{ 'is-invalid': submitted2 && g.confirmat.errors }"
                                    class="form-control rounded-pill border-3  px-4" required>
                                <div *ngIf=" g.confirmat.errors" class="invalid-feedback">
                                    <div *ngIf="g.confirmat.errors.required">{{'Error_field' | translate}}</div>
                                </div>


                            </div>

                            <button type="button" class="btn btn-primary btn-style mt-4 float-right"
                                (click)="SendFormpassword()"
                                style="margin-right: 5px; border-color:#cbd4d8 ; background-color: #87D0F3;">{{'shared_5'
                                | translate}}</button>

                        </form>


                    </div>
                    <div *ngIf="step4">
                        <label *ngIf="selectedLang == 'en'">
                            <h2>Enter your code and press enter</h2>
                        </label>
                        <label *ngIf="selectedLang == 'fr'">
                            <h2>Entrez votre code et appuyez sur la touche Entrée</h2>
                        </label>
                        <input input type="text" id="code" class="form-control rounded-pill border-3  px-4"
                            (change)="checkcode()" />
                        <div *ngIf=" codeverification == false" class="error-text">
                            <div *ngIf="selectedLang == 'en'">Code does not match with used link</div>
                            <div *ngIf="selectedLang == 'fr'">Le code ne correspond pas au lien utilisé</div>
                        </div>
                        <!-- REcaptcha bloc-->
                        <div *ngIf="activaterecaptcha2" class="from-group ml-1">
                            <div class="g-recaptcha" data-sitekey="12345" data-size="invisible" data-badge="inline">
                                <div class="captcha2">
                                    <script
                                        src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
                                        async defer></script>
                                    <re-captcha (resolved)="resolved2($event)" siteKey={{keyrecaptcha}}
                                        required></re-captcha>


                                    <div class="form-error-msg" hidden>
                                        <span class="error-text">{{'Error_field' | translate}}</span>
                                    </div>
                                </div>
                                <div [hidden]="!captcha">
                                </div>
                            </div>

                        </div>

                    </div>
                   <br>
                </div>
            </div>
        </div>
    </div>
</div>