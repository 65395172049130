import { Component, OnInit , Renderer2, Input} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, ChildActivationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { User } from './models/user';
import { AuthService } from './services/auth.service';
import { StorageService } from './services/storage.service';
import { CookieService } from 'ngx-cookie-service';
import { v4 as uuidv4 } from 'uuid';
import {TranslateService} from '@ngx-translate/core';
import  * as io   from 'socket.io-client';
import { ToastrService } from 'ngx-toastr';
import { environment} from '../environments/environment';
import { DataService } from "./data.service";
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
private socket: any;
public data: any;
private SIGNING_KEY: string;
private cookieValue: string;
public expDate= new Date();

fooSrc: Subject<number>;
perf_fonc_cookies_checked: boolean;
pub_cookies_checked: boolean;
analysis_cookies_checked: boolean;

consent_policy = {
  'ess': null,
  'func': null,
  'adv': null,
  'pers': null,
  'dts': null,
  'ts': null
};
currentLanguage: string;

  constructor(private titleService: Title, private router: Router, private route: ActivatedRoute, public translate: TranslateService,
              private storageService: StorageService, public dataService: DataService, private toaster: ToastrService,private authService: AuthService, private cookieService: CookieService,private formBuilder: FormBuilder,) {

    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');
    if(localStorage.getItem('currentLang')){
      translate.use(localStorage.getItem('currentLang'));  
    }else{
      translate.use('en');
    }
    
    this.router.events
    .pipe(filter(event => event instanceof ChildActivationEnd))
    .subscribe(event => {
        let snapshot = (event as ChildActivationEnd).snapshot;
        while (snapshot.firstChild !== null) {
            snapshot = snapshot.firstChild;
        }
        this.titleService.setTitle(snapshot.data.title || 'Admin DashBoard');
    });
    this.socket = io.connect(environment.backUrl);
    this.fooSrc = this.dataService.fooSource;
    this.changeFoo(90);

    // this.socket = io.connect('http://127.0.0.1:8000'); // for  local use





  }
  switchLang(lang: string) {
    if(lang){
      this.translate.use(lang);
    const currentLang = this.translate.currentLang;
    localStorage.setItem("currentLang", currentLang);
    this.getLangue();
    }else{
      lang ='fr'
      this.translate.use(lang);
      const currentLang = this.translate.currentLang;
      localStorage.setItem("currentLang", currentLang);
      this.getLangue();
    }
    
  }
  getLangue(){
    return this.currentLanguage
  }
  

  ngOnInit(): void {
    const currentLang = this.translate.currentLang;
    localStorage.setItem("currentLang", currentLang);
    if ((this.cookieService.get('consent-policy'))) {
      document.getElementById("cookies-banner").setAttribute('style', 'display: none');
    }
    this.SIGNING_KEY = uuidv4();
    
    if (this.storageService.read(StorageService.USER_TOKEN_KEY)) {
      const token = this.storageService.read(StorageService.USER_TOKEN_KEY)
      this.authService.getLoggedUser().subscribe(
          res => {
              this.authService.user = res as User;
              if(res.privilege_id.name == "Admin" || res.privilege_id.name == "RH"){
                this.socket.on('tx', data => {

                  this.data = data;
                  if((this.data).indexOf('|') > 0 && res.company_id){
                    var company_id_socket = (this.data).split('|')[1];
                    if(company_id_socket == res.company_id ){
                      this.toaster.info( (this.data).split('|')[0])
                    this.changeFoo(90);
                  }
                  }
                }, err=>{
                  console.log(err)
                });

              }
              
              if(res.privilege_id.name == "Admin" || res.privilege_id.name == "RH"  || res.privilege_id.name == "Accountant"){
                this.socket.on('txe', data => {

                  this.data = data;
                  if((this.data).indexOf('|') > 0 && res.company_id){
                    var company_id_socket = (this.data).split('|')[1];
                    if(company_id_socket == res.company_id && (res.privilege_id.name == "Admin" || res.privilege_id.name == "RH"  || res.privilege_id.name == "Accountant")){
                      this.toaster.info( (this.data).split('|')[0])
                    this.changeFoo(90);
                  }
                  }
                });

             

              }

              
              else{
                this.socket.on('fx', data1 => {
                  this.data = data1;

                  if((this.data).indexOf('|') > 0 && res._id){
                    var user_id_socket = (this.data).split('|')[1];
                    if(user_id_socket == res._id && (res.privilege_id.name != "Admin")){
                    this.toaster.info( (this.data).split('|')[0])
                    this.changeFoo(90);
                  }
                  }
                });
              }
              
              
              
          
          },
          error => {
              this.storageService.removeAll();
              this.router.navigate(['/']);
                      }
      );
  }
  
}
check_cookies() {
  if ((this.cookieService.get('consent-policy'))) {
    document.getElementById('cookies-banner').setAttribute('style', 'display: none');
  }
  // console.log('changed');
}
check_cookies_params() {
  if ((this.cookieService.get('consent-policy'))) {
    document.getElementById('cookies-param').setAttribute('style', 'display: none');
    document.getElementById('cookies-banner').setAttribute('style', 'display: none');
  }
  // console.log('changed');
}
cookie_settings() {
  document.getElementById('cookies-param').setAttribute('style', 'display: block');
}

close_settings() {
  document.getElementById('cookies-param').setAttribute('style', 'display: none');
}

dismiss_all_cookie() {
  this.consent_policy = {
    'ess': 0,
    'func': 0,
    'adv': 0,
    'pers': 0,
    'dts': 0,
    'ts': this.SIGNING_KEY
  };
  this.cookieService.set('consent-policy', JSON.stringify(this.consent_policy));
  this.cookieValue = this.cookieService.get('consent-policy');
  document.getElementById('cookies-banner').setAttribute('style', 'display: none');
  document.getElementById('cookies-param').setAttribute('style', 'display: none');
  // console.log('all-refused : ', this.cookieValue);

}

accept_all_cookie() {
  this.consent_policy = {
    'ess': 1,
    'func': 1,
    'adv': 1,
    'pers': 1,
    'dts': 4,
    'ts': this.SIGNING_KEY
  };
  this.cookieService.set('consent-policy', JSON.stringify(this.consent_policy));
  this.cookieValue = this.cookieService.get('consent-policy');
  document.getElementById('cookies-banner').setAttribute('style', 'display: none');
  document.getElementById('cookies-param').setAttribute('style', 'display: none');
  // console.log('consent-policy : ', this.cookieValue);
}

accept_some_cookie() {
  let counter = 1;
  counter = counter + (this.perf_fonc_cookies_checked ? 1 : 0) + (this.pub_cookies_checked ? 1 : 0) + (this.analysis_cookies_checked ? 1 : 0);
  this.consent_policy = {
    'ess': 1,
    'func': (this.perf_fonc_cookies_checked ? 1 : 0),
    'adv': (this.pub_cookies_checked ? 1 : 0),
    'pers': (this.analysis_cookies_checked ? 1 : 0),
    'dts': counter,
    'ts': this.SIGNING_KEY
  };
  this.cookieService.set('consent-policy', JSON.stringify(this.consent_policy));
  this.cookieValue = this.cookieService.get('consent-policy');
  document.getElementById('cookies-banner').setAttribute('style', 'display: none');
  document.getElementById('cookies-param').setAttribute('style', 'display: none');
  // console.log('consent-policy : ', this.cookieValue);
}

checked() {
  const perf_fonc_cookies = <HTMLInputElement>document.getElementById('perf_fonc_cookies');
  const pub_cookies = <HTMLInputElement>document.getElementById('pub_cookies');
  const analysis_cookies = <HTMLInputElement>document.getElementById('analysis_cookies');
  this.perf_fonc_cookies_checked = perf_fonc_cookies.checked;
  this.pub_cookies_checked = pub_cookies.checked;
  this.analysis_cookies_checked = analysis_cookies.checked;
  /*console.log(this.perf_fonc_cookies_checked);
  console.log(this.pub_cookies_checked);
  console.log(this.analysis_cookies_checked);*/
}
changeFoo(x) {
  this.dataService.changeFoo(x);
}
opencookies(event){
  event.preventDefault();
  const link = event.target as HTMLAnchorElement;
  const url = link.href;
  const title = 'coockies';
  window.open(url, `noopener,title=${title}`);
  
}
}

