<div>
  <!-- sidebar menu start -->
  <div class="sidebar-menu sticky-sidebar-menu">

    <!-- logo start -->
    <!-- <div class="logo">
      <h1><a routerLink="/">Time Sheet</a></h1>
    </div> -->

    <!-- if logo is image enable this -->
    <!-- image logo -->
    <div class="logo">
      <!-- <a *ngIf="loggedUser.privilege_id.name == 'Admin' || loggedUser.is_superuser" -->
      <a routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
        <a *ngIf="loggedUser.privilege_id.name == 'Admin'" routerLink="/dashboard/admin">
          <img src="{{imageUrl}}" alt="Voguel Consulting" title="logo" class="img-fluid"
            style="height:80px; margin-right: 50px;" />
        </a>

        <a *ngIf="loggedUser.privilege_id.name == 'Client' || loggedUser.privilege_id.name == 'Employee' || loggedUser.privilege_id.name == 'Accountant'"
          routerLink="/dashboard/client">
          <img src="{{imageUrl}}" alt="Voguel Consulting" title="logo" class="img-fluid"
            style="height:80px; margin-right: 50px;" />
        </a>
      </a>
      <a *ngIf="loggedUser.is_active && loggedUser.privilege_id.name === 'RH' " routerLinkActive="active"
        [routerLinkActiveOptions]="{exact:
                true}">
        <a routerLink="/dashboard/admin">
          <img src="{{imageUrl}}" alt="Voguel Consulting" title="logo" class="img-fluid"
            style="height:80px; margin-right: 50px;" />
        </a>
      </a>
    </div>
    <!-- //image logo -->

    <div class="logo-icon text-center">
      <a routerLink="/dashboard/admin" title="logo"><img src="{{imageUrl}}" alt="Voguel Consulting"> </a>
    </div>
    <!-- //logo end -->

    <div class="sidebar-menu-inner">

      <!-- sidebar nav start -->
      <ul class="nav nav-pills nav-stacked custom-nav">


        <!--      Start Module Dashboard      -->

        <li class="menu-list">
          <a href=""><i class="fa fa-home"></i>
            <span>Dashboard<i class="lnr lnr-chevron-right"></i></span></a>
          <ul class="sub-menu-list">
            <li *ngIf="loggedUser.is_active && loggedUser.privilege_id.name === 'Employee' || loggedUser.is_active && loggedUser.privilege_id.name === 'Client'"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/client">{{'menu_1_1'|translate}}</a></li>
            <li *ngIf="loggedUser.privilege_id.name == 'Admin' || loggedUser.is_superuser" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/admin">{{'menu_1_1'|translate}}</a></li>
            <li *ngIf="loggedUser.privilege_id.name == 'Admin' || loggedUser.is_superuser" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/calendar">{{'menu_1_2'|translate}}</a> </li>

          </ul>
        </li>

                                                      <!--      End Module Dashboard      -->
                                                      <!--      Start Module User      -->

        <li *ngIf="loggedUser.is_active && loggedUser.privilege_id.name === 'Admin' " class="menu-list">
          <a href="#"><i class="fa fa-building "></i>
            <span>{{'menu_2_0'|translate}}<i class="lnr lnr-chevron-right"></i></span></a>
          <ul class="sub-menu-list">
            <li *ngIf="loggedUser.privilege_id.name == 'Admin' && loggedUser.is_superuser" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:
                  true}"><a routerLink="/dashboard/company">{{'menu_2_1'|translate}}</a> </li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                  true}"><a routerLink="/dashboard/company/me">{{'menu_2_2'|translate}}</a> </li>
            <!-- <li *ngIf="loggedUser.privilege_id.name == 'Admin' && loggedUser.is_superuser" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:
                  true}"><a routerLink="/dashboard/company/company">Add Company</a> </li> -->
            <li *ngIf="loggedUser.is_superuser" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                  true}"><a routerLink="/dashboard/company/request">{{'menu_2_3'|translate}}</a> </li>


          </ul>
        </li>

                                                        <!--      End Module Users     -->

                                                        <!--      Start Module User      -->

        <li class="menu-list"
          *ngIf="loggedUser.privilege_id.name == 'Accountant' || loggedUser.privilege_id.name == 'Admin' || loggedUser.is_superuser || loggedUser.privilege_id.name == 'RH'">
          <a href="#">
            <i class="fa fa-users"></i>
            <span>{{'menu_3_0'|translate}}<i class="lnr lnr-chevron-right"></i></span></a>
          <ul class="sub-menu-list">
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/users">{{'menu_3_1'|translate}}</a> </li>
            <!-- <li *ngIf="loggedUser.privilege_id.name != 'Accountant'" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/users/create-user">{{'menu_3_2'|translate}}</a></li> -->

          </ul>
        </li>

                                                        <!--      End Module Users     -->
                                                        <!--      Start Module Project      -->

        <li *ngIf="loggedUser.is_active " class="menu-list">
          <a href="#"><i class="fa fa-folder-open "></i>
            <span>{{'menu_4_0'|translate}}<i class="lnr lnr-chevron-right"></i></span></a>
          <ul class="sub-menu-list">
            <li *ngIf="loggedUser.privilege_id.name == 'Admin' || loggedUser.is_superuser" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:
                        true}"><a routerLink="/dashboard/projects">{{'menu_4_1'|translate}}</a> </li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                        true}"><a routerLink="/dashboard/projects/me">{{'menu_4_2'|translate}}</a> </li>
            <!-- <li *ngIf="loggedUser.privilege_id.name == 'Admin' || loggedUser.is_superuser" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:
                        true}">
                        <a routerLink="/dashboard/projects/project">{{'menu_4_3'|translate}}</a> </li> -->

          </ul>
        </li>

                                                <!--      End Module Project      -->

                                                <!-- =============== Start Module Paysheets =============== -->
        <li class="menu-list">
          <a href="#">
            <i class="fa fa-users"></i>
            <span>{{'menu_5_0'|translate}}<i class="lnr lnr-chevron-right"></i></span></a>
          <ul class="sub-menu-list">
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/paysheets">{{'menu_5_1'|translate}}</a> </li>
            <!-- <li
              *ngIf="loggedUser.privilege_id.name == 'Admin' || loggedUser.is_superuser || loggedUser.privilege_id.name == 'RH' || loggedUser.privilege_id.name == 'Accountant'"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/paysheets/create-paysheet">{{'menu_5_2'|translate}}</a></li> -->
          </ul>
        </li>
                                                            <!-- =============== End of Module Paysheets =============== -->
                                                            <!-- =============== Start Module Invoices =============== -->
        <li *ngIf="loggedUser.privilege_id.name == 'Admin' || loggedUser.is_superuser || loggedUser.privilege_id.name == 'RH' || loggedUser.privilege_id.name == 'Accountant'" class="menu-list">
          <a href="#">
            <i class="fa fa-file"></i>
            <span>{{'menu_6_0'|translate}}<i class="lnr lnr-chevron-right"></i></span></a>
          <ul class="sub-menu-list">
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/invoices">{{'menu_6_1'|translate}}</a> </li>
            <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/invoices/create-invoice">{{'menu_6_2'|translate}}</a> </li> -->
          </ul>
        </li>
                                                              <!-- =============== End of Module Refund =============== -->
        <li class="menu-list" *ngIf="loggedUser.privilege_id.name != 'Client'">
          <a href="#">
            <i class="fa fa-dollar"></i>
            <span>{{'menu_7_0'|translate}}<i class="lnr lnr-chevron-right"></i></span></a>
          <ul class="sub-menu-list">
            <li *ngIf="loggedUser.privilege_id.name != 'Employee'" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/refunds">{{'menu_7_1'|translate}}</a> </li>
            <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
              true}"><a routerLink="/dashboard/refunds/me">{{'menu_7_2'|translate}}</a> </li>
            <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/refunds/add">{{'menu_7_3'|translate}}</a> </li> -->
          </ul>
                                                               <!-- =============== End of Module Refund =============== -->

                                                              <!--      Start Module Presence      -->

        <li *ngIf="loggedUser.is_active" class="menu-list">
          <a href="#"><i class="fa fa-calendar-plus-o"></i>
            <span>{{'menu_8_0'|translate}}<i class="lnr lnr-chevron-right"></i></span></a>
          <ul class="sub-menu-list">
            <li
              *ngIf="loggedUser.privilege_id.name == 'Admin' || loggedUser.is_superuser || loggedUser.privilege_id.name == 'Accountant'"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/presences">{{'menu_8_1'|translate}}</a> </li>
            <li *ngIf="loggedUser.privilege_id.name != 'Accountant' " routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/presences/me">{{'menu_8_2'|translate}}</a> </li>
            <!-- <li *ngIf="loggedUser.privilege_id.name == 'RH' || loggedUser.privilege_id.name == 'Employee' " routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/presences/presence">{{'menu_8_3'|translate}}</a> </li> -->

          </ul>
        </li>

                                                                <!--      End Module Presence      -->

                                                                <!--      Start Module Absence  + Holidays    -->

        <li *ngIf="loggedUser.is_active" class="menu-list">
          <a href="#"><i class="fa fa-calendar-minus-o"></i>
            <span>{{'menu_9_0'|translate}}<i class="lnr lnr-chevron-right"></i></span></a>
          <ul class="sub-menu-list">
            <li *ngIf="loggedUser.privilege_id.name == 'Admin' || loggedUser.is_superuser || loggedUser.privilege_id.name == 'Accountant'|| loggedUser.privilege_id.name == 'RH'"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/absences">{{'menu_9_1'|translate}}</a> </li>
            <!-- <li *ngIf="loggedUser.privilege_id.name == 'Admin' || loggedUser.is_superuser || loggedUser.privilege_id.name == 'Accountant' || loggedUser.privilege_id.name == 'RH'"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                  true}"><a routerLink="/dashboard/absences/leave/leaveALL">{{'menu_9_2'|translate}}</a> </li> -->
            <li *ngIf="loggedUser.privilege_id.name != 'Accountant'" routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/absences/me">{{'menu_9_3'|translate}}</a> </li>
            <!-- <li *ngIf="loggedUser.privilege_id.name != 'Accountant' " routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                  true}"><a routerLink="/dashboard/absences/leave/leave/me">{{'menu_9_4'|translate}}</a> </li> -->
            <!-- <li *ngIf="loggedUser.privilege_id.name == 'Employee' " routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/absences/absence">{{'menu_9_6'|translate}}</a> </li> -->
            <!-- <li  *ngIf="loggedUser.privilege_id.name == 'Admin' || loggedUser.privilege_id.name == 'RH' || loggedUser.privilege_id.name == 'Accountant' " routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                  true}"><a routerLink="/dashboard/absences/absence-create">{{'menu_9_5'|translate}}</a> </li> -->
            
            
            <!-- <li *ngIf="loggedUser.privilege_id.name != 'Accountant' " routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                    true}"><a routerLink="/dashboard/absences/leave/leave">Add Holiday</a> </li> -->

          </ul>
        </li>

                                                        <!--      End Module Absence      -->
                                                        <!--      Start Module Holidays      -->

        <!-- <li *ngIf="loggedUser.is_active" class="menu-list">
          <a href="#"><i class="fa fa-calendar-times-o"></i>
            <span>Holidays<i class="lnr lnr-chevron-right"></i></span></a>
          <ul class="sub-menu-list">
            <li
              *ngIf="loggedUser.privilege_id.name == 'Admin' || loggedUser.is_superuser || loggedUser.privilege_id.name == 'Accountant' || loggedUser.privilege_id.name == 'RH'"
              routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/absences/leave/leaveALL">Holiday List</a> </li>
            <li *ngIf="loggedUser.privilege_id.name != 'Accountant' " routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/absences/leave/leave/me">My Holidays</a> </li>
            <li *ngIf="loggedUser.privilege_id.name != 'Accountant' " routerLinkActive="active"
              [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/absences/leave/leave">Add Holiday</a> </li>

          </ul>
        </li> -->
                                                    <!-- START  MODULE DOCUMENT-->
        <li  class="menu-list">
          <a href="#"><i class="fa fa-file"></i>
            <span>{{'menu_10_0'|translate}}<i class="lnr lnr-chevron-right"></i></span></a>
          <ul class="sub-menu-list">
            <!-- <li *ngIf="loggedUser.privilege_id.name == 'Admin' ||loggedUser.privilege_id.name == 'Accountant'" 
            
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                true}"><a routerLink="/dashboard/document/generate">{{'menu_10_1'|translate}}</a> 
              </li> -->
                <li *ngIf="loggedUser.privilege_id.name == 'Admin' ||loggedUser.privilege_id.name == 'Accountant' ||loggedUser.privilege_id.name == 'RH'"  routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                    true}"><a routerLink="/dashboard/document">{{'menu_10_2'|translate}}</a> </li>
                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                  true}"><a routerLink="/dashboard/document/me">{{'menu_10_3'|translate}}</a> </li>
                <!-- <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                    true}"><a routerLink="/dashboard/refunds/add">{{'menu_7_3'|translate}}</a> </li> -->

          </ul>
        </li>
                                                              <!--      End Module DOCUMENT      -->
      </ul>
                                                                <!-- //sidebar nav end -->
      <!-- toggle button start -->
      <a class="toggle-btn">
        <i class="fa fa-angle-double-left menu-collapsed__left pull-right"><span></span></i>
        <i class="fa fa-angle-double-right menu-collapsed__right"></i>
      </a>
      <!-- //toggle button end -->
    </div>
  </div>
  <!-- //sidebar menu end -->
                                                                  <!-- header-starts -->
  <div class="header ">

    <!-- notification menu start -->
    <div class="menu-right" id="haya">
      <div class="navbar user-panel-top">
        <!-- <div class="search-box">
          <form>
            <input class="search-input" placeholder="Search Here..." type="search" id="search">
            <button class="search-submit" value=""><span class="fa fa-search"></span></button>
          </form>
        </div> -->
        <div class="user-dropdown-details d-flex">
          <div class="profile_details_left">
            <ul class="nofitications-dropdown">
              <li class="dropdown">
                <!-- <a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false" disable> -->
                  <a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"  disable>
                  <i style="opacity: 100 !important;"> <img *ngIf="selectedLang == 'fr'" src="/assets/images/fr.svg" alt="" style="cursor: pointer;"> <img *ngIf="selectedLang == 'en'" src="/assets/images/uk.svg" alt="" style="cursor: pointer;"> </i></a>
                <ul class="dropdown-menu">
                  
                  <li *ngIf="selectedLang == 'en'">
                      <div class="flag_img" (click)="lang('fr')" style="cursor: pointer;"><img src="/assets/images/fr.svg" alt="fr" style="cursor: pointer;"> Français</div>
                      
                    </li>
                  <li  *ngIf="selectedLang == 'fr'" class="odd">
                      <div class="flag_img" (click)="lang('en')" style="cursor: pointer;"><img src="/assets/images/uk.svg" alt="en" style="cursor: pointer;"> English</div>
                      
                    </li>
                </ul>
              </li>
              <li  class="dropdown" >
                <a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"  disable>
                <div class="icon" id="bell"> <i class="fa fa-bell-o" style="background-color: transparent; opacity: 100 !important;"><label *ngIf="Notificationlength > 0" class="badge blue" style=" padding-left: 3px;padding-right: 3px;opacity: 100% !important;">{{Notificationlength}}</label></i></div></a>
                <ul class="notifications dropdown-menu" id="box"style="min-width: 330px; height: 33rem; overflow: auto;">
                    <h2 *ngIf="Notificationlength > 0" class="no-btm">Notifications : <span>{{Notificationlength}}</span></h2>
                    <h2 *ngIf="Notificationlength  == 0">No new notifications </h2>
                    <h5 *ngIf="Notificationlength > 0" (click)="readall()"  style="cursor: pointer;text-align: right;"> Mark all messages as read</h5>
                    <!-- Today  notification-->
                    <div  *ngIf="arr && arr.length >0"  class="notifications-item-title" style="margin-top: 0 !important;"> 
                      Today
                    </div>
                    <a *ngFor="let item of arr">
                    <div class="notifications-item"> 
                      <a *ngIf="item.type !='Holiday' && item.type !='refund'  && item.type !='Document' " ><img src="../../../assets/images/ill.png" ></a>
                      <a *ngIf="item.type =='Holiday' "  ><img src="../../../assets/images/holiday.png" ></a>
                      <a *ngIf="item.type =='refund' " ><img src="../../../assets/images/refund.png" ></a>
                      <a *ngIf="item.type =='Document' "  ><img src="../../../assets/images/doc.png" ></a>
                          <div class="text"  >
                            <i *ngIf="item.read == false" class=" fa fa-circle icon-fea fa-1x notRead" title="Mark as seen" (click)="Markasread(item)"></i> 
                            <i *ngIf="item.read == true" class="fa fa-check icon-fea fa-1x notRead" title="Mark as unseen" (click)="Markasnotread(item)"></i>
                            <h4 class="empl_name " (click)="RedirectMe(item)">{{item.type}}
                            </h4>
                            <p class="desc_notif" (click)="RedirectMe(item)">{{item.Content}}</p>
                          </div>
                      
                    </div>
                 
                  </a>
                  <!-- yestarday notification-->
                  <div *ngIf="arr1 && arr1.length >0"  class="notifications-item-title"> 
                    Yesterday
                  </div>
                  <a *ngFor="let item of arr1">
                    <div class="notifications-item"> 
                      <a *ngIf="item.type !='Holiday' && item.type !='refund'  && item.type !='Document' " ><img src="../../../assets/images/ill.png" ></a>
                      <a *ngIf="item.type =='Holiday' "  ><img src="../../../assets/images/holiday.png" ></a>
                      <a *ngIf="item.type =='refund' " ><img src="../../../assets/images/refund.png" ></a>
                      <a *ngIf="item.type =='Document' "  ><img src="../../../assets/images/doc.png" ></a>
                        
                          <div class="text"  >
                            <i *ngIf="item.read == false" class="fa fa-circle icon-fea fa-1x notRead" title="Mark as seen" (click)="Markasread(item)"></i> 
                            <i *ngIf="item.read == true" class="fa fa-check icon-fea fa-1x notRead " title="Mark as unseen" (click)="Markasnotread(item)"></i>
                            <h4 class="empl_name " (click)="RedirectMe(item)">{{item.type}}
                            </h4>
                            <p class="desc_notif" (click)="RedirectMe(item)">{{item.Content}}</p>
                          </div>
                      
                    </div>
                 
                  </a>
                  <!-- Previous notificaton -->
                  <div *ngIf="arr2 && arr2.length >0"  class="notifications-item-title"> 
                    The oldest
                  </div>
                  <a  *ngFor="let item of arr2">
                    <div class="notifications-item"> 
                      <a *ngIf="item.type !='Holiday' && item.type !='refund'  && item.type !='Document' " ><img src="../../../assets/images/ill.png" ></a>
                      <a *ngIf="item.type =='Holiday' "  ><img src="../../../assets/images/holiday.png" ></a>
                      <a *ngIf="item.type =='refund' " ><img src="../../../assets/images/refund.png" ></a>
                      <a *ngIf="item.type =='Document' "  ><img src="../../../assets/images/doc.png" ></a>
                        
                          <div class="text"  >
                            <i *ngIf="item.read == false" class="fa fa-circle icon-fea fa-1x notRead" title="Mark as seen" (click)="Markasread(item)"></i> 
                            <i *ngIf="item.read == true" class="fa fa-check icon-fea fa-1x notRead " title="Mark as unseen" (click)="Markasnotread(item)"></i>
                            <h4 class="empl_name " (click)="RedirectMe(item)">{{item.type}}
                            </h4>
                            <p class="desc_notif" (click)="RedirectMe(item)">{{item.Content}}</p>
                          </div>
                      
                    </div>
                 
                  </a>
                    
                  </ul>
              </li>
              
              <!-- <li class="dropdown"> -->
                <!-- <a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"> -->
                <!-- <a class="dropdown-toggle" aria-expanded="false">
                  <i class="fa fa-comment-o"></i><span class="badge blue" disable>4</span></a>
                <ul class="dropdown-menu">
                  <li>
                    <div class="notification_header">
                      <h3>You have 4 new messages</h3>
                    </div>
                  </li>
                  <li><a class="grid">
                      <div class="user_img"><img src="/assets/images/avatar1.jpg" alt=""></div>
                      <div class="notification_desc">
                        <p>Johnson purchased template</p>
                        <span>Just Now</span>
                      </div>
                    </a></li>
                  <li>
                    <div class="notification_bottom">
                      <a href="#all" class="bg-primary">See all messages</a>
                    </div>
                  </li>
                </ul>
              </li> -->
            </ul>
          </div>
          <div class="profile_details">
            <ul>
              <li class="dropdown profile_details_drop">
                <a class="dropdown-toggle" data-toggle="dropdown" id="dropdownMenu3" aria-haspopup="true"
                  aria-expanded="false">
                  <div class="profile_img">
                    <img src="{{imageUrl2}}{{Profil_default}}" class="rounded-circle" alt=""/>
                    <div class="user-active">
                      <span></span>
                    </div>
                  </div>
                </a>
                <ul class="dropdown-menu drp-mnu" aria-labelledby="dropdownMenu3">
                  <li class="user-info">
                    <h5 class="user-name">{{loggedUser?.email}}</h5>
                  </li>
                  <li> <a routerLink="/dashboard/users/my-profile"><i class="lnr lnr-user"></i>My Profile</a> </li>
                  <li class="logout" style="cursor: pointer;"> <a (click)="logout()"><i class="fa fa-power-off"></i>
                      Logout</a> </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--notification menu end -->
  </div>
                                                              <!-- //header-ends -->
  <!-- main content start -->

  <!-- main content end-->
</div>