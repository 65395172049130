import { User } from "./user";
import { Address } from "./address";

export class Company{
    _id?:string;
    name: string;
    slug:string;
    owner_id: User;
    logo: string;
    Lanced_date: Date;
    accountant: User;
    tax_ID: string;
    commercial_register: String;
    domain:string;
    adress?:Address;
    masqued: boolean;
    employees:User[];
    createdAt: Date;
}

export class CompanyRequest {
    _id?:string;
    name: string;
    slug:string;
    owner_id: User;
    logo: string;
    Lanced_date: Date;
    accountant: User;
    tax_ID: string;
    commercial_register: String;
    domain:string;
    address_street: string;
    address_postal_code: string;
    address_city: string;
    masqued: boolean;
    employees:User[];
    createdAt: Date;
}  