import { Component, OnInit } from '@angular/core';
import {UserService} from 'src/app/services/user.service';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertModuleComponent } from '../shared/alert-module/alert-module.component';
import {Router} from '@angular/router';
import { environment } from 'src/environments/environment';



declare var $:JQueryStatic;

@Component({
  selector: 'app-account-confirmation',
  templateUrl: './account-confirmation.component.html',
  styleUrls: ['./account-confirmation.component.scss']
})
export class AccountConfirmationComponent implements OnInit {
  keyrecaptcha = environment.recaptchaKeyPublic;

  CurrentUser: any;
  Validation:boolean = false;
  captcha: string;
  email: string;
  recaptch_checked:boolean=false;

  constructor(
    private userService : UserService,
    private dialog: MatDialog,
    private router: Router
  ) { 
    this.captcha = '';
    this.email = 'mouhamed.charfii@gmail.com';
  }

  ngOnInit(): void {
  }

  verify(){
    if (this.captcha == '') {
      $('.captcha').find('[required]').each(function () {
          $(this).parent().find('.form-error-msg').removeAttr('hidden');
          
      })
      return;
    }else{
      $(this).parent().find('.form-error-msg').attr('hidden');
      this.recaptch_checked =true;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '20%';
    var code  =  $('#codeVerification').val();
    $("#code-verification-form").find('.form-error-msg').attr('hidden', 'true');
    this.userService.UserVerificationCode(code).subscribe((result)=>{
      
      this.CurrentUser = result;
      if(this.CurrentUser.is_active){
        dialogConfig.data = {
          title:"Account Confirmation",
          message: "This account is already validate and active. You are redirected to login page",
          choise: ['Close'],
      }
      const alertbox = this.dialog.open(AlertModuleComponent, dialogConfig);
      this.router.navigate(['/']);
      }
      this.Validation = true;
    }, (err) =>{
      $("#code-verification-form").find('.form-error-msg').removeAttr('hidden');
    })

  }

  submitNewPasswordForm(form: NgForm){
    $('[name = submitpasswordbtn]').prop('disabled', true);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '20%';
    var new_password = $('[name=newPassword]').val();
    var confirm_new_password = $('[name=confirmNewPassword]').val();
    if (this.checkEmptyFields($('#password-form')) == true) {
      $('[name = submitpasswordbtn]').prop('disabled', false);   
      return;
    }
    if(new_password == confirm_new_password){
      var data = {
          "new_password": new_password
      }
      this.userService.confirmUserAccount(this.CurrentUser._id, data).subscribe(
        (res) => {
            dialogConfig.data = {
                title:"Password update",
                message: res['response'],
                choise: ['Close'],
            }
            const alertbox = this.dialog.open(AlertModuleComponent, dialogConfig)
            alertbox.afterClosed().subscribe(()=>{
                $('[name=password]').val("");
                $('[name=newPassword]').val("");
                $('[name=confirmNewPassword]').val("");
            });
            this.router.navigate(['/']);
        },(err) => {
            $('[name = submitpasswordbtn]').prop('disabled', false);

            if (err.status == 400){
                
                dialogConfig.data = {
                    title:"Password update",
                    message: "Please enter the right password",
                    choise: ['Close'],
                }
                const alertbox = this.dialog.open(AlertModuleComponent, dialogConfig);
                $('[name=password]').addClass('error-field');
            } else if (err.status == 401){
                dialogConfig.data = {
                    title:"Password update",
                    message: "Password should be combination of one uppercase , one lower case,  one digit and min 8 , max 20 char long",
                    choise: ['Close'],
                }
                const alertbox = this.dialog.open(AlertModuleComponent, dialogConfig);
                $('.new-password').addClass('error-field');
            }
        }
    )
    }
    else{
      $('[name = submitpasswordbtn]').prop('disabled', false);

      dialogConfig.data = {
          title:"Password update",
          message: "New password & confirm password must be identical",
          choise: ['Close'],
      }
      const alertbox = this.dialog.open(AlertModuleComponent, dialogConfig);
      $('.new-password').addClass('error-field');
  }
  }

  checkEmptyFields($section){
    let is_empty: boolean;
    is_empty = false;
    $section.find('[required]').each(function(){
        if (($(this).val() == '') || (!$(this).val())) {
            $(this).addClass('error-field');
            $(this).parent().find('.form-error-msg').removeAttr('hidden');
            is_empty = true;
        } else {
            $(this).removeClass('error-field');
            $(this).parent().find('.form-error-msg').attr('hidden', 'true');
        }
    })
    return is_empty;
}
resolved(captchaResponse: string) {
  this.captcha = captchaResponse;
}




}
