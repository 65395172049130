import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullLayoutComponent } from './full-layout/full-layout.component';
import { RhGuard } from './guards/rh-guard.guard';
import { AdminGuard } from './guards/admin-guard.guard';
import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import {SignUpComponent} from './modules/sign-up/sign-up.component';
import {AccountConfirmationComponent} from './modules/account-confirmation/account-confirmation.component';
import {UserGuard} from './guards/user-guard.guard';
import {AdminRHGuard} from './guards/admin-rh-guard.guard';
import {AdminOraccountantGuard} from './guards/accountant-admin-guard.guard';
import {AdminRHaccountantGuard} from './guards/accountant-rh-admin-guard.guard';

import {ErrorComponent} from './full-layout/error/error.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    data: { title: 'Login' },
    canActivate: [],
  },
  {
    path: 'resetpassword',
    component: ForgetPasswordComponent,
    data: { title: 'Reset password' },
    canActivate: [],
  },
  {
    path: 'accountconfirmation',
    component: AccountConfirmationComponent,
    data: { title: 'Account confirmation' },
    canActivate: [],
  },
  {
    path: 'signup',
    component: SignUpComponent,
    data: { title: 'Sign Up' },
    canActivate: [],
  },
  {
    path: 'dashboard',
    component: FullLayoutComponent,
    data: { title: 'Dashboard' },
    canActivate: [UserGuard],
    children: [
      {
        path: '', loadChildren: () =>
          import('./modules/admin-dashboard/admin-dashboard.module').then(
            (m) => m.AdminDashboardModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: 'company',
        loadChildren: () =>
          import('./modules/company-management/company-management.module').then(
            (m) => m.CompanyManagementModule
          ),
        canActivate: [AdminGuard],
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./modules/users-management/users-management.module').then(
            (m) => m.UsersManagementModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: 'paysheets',
        loadChildren: () =>
          import('./modules/paysheets-management/paysheets-managements.module').then(
            (m) => m.PaysheetsManagementsModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: 'presences',
        loadChildren: () =>
          import('./modules/presence-management/presence-management.module').then(
            (m) => m.PresenceManagementModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: 'projects',
        loadChildren: () =>
          import('./modules/project-management/project-management.module').then(
            (m) => m.ProjectManagementModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: 'invoices',
        loadChildren: () =>
          import('./modules/invoice-management/invoice-management.module').then(
            (m) => m.CompanyManagementModule
          ),
        canActivate: [AdminRHaccountantGuard],
      },
      {
        path: 'refunds',
        loadChildren: () =>
          import('./modules/refund-management/refund-management.module').then(
            (m) => m.CompanyManagementModule
          ),
        canActivate: [UserGuard],
      },
      {
        path: 'absences',
        loadChildren: () =>
          import('./modules/absence-management/absence-management.module').then(
            (m) => m.AbsenceManagementModule
          ),
        canActivate: [UserGuard],
      },
      {
         path: 'absences/leave',
         loadChildren: () =>
           import('./modules/holidays-management/holidays-management.module').then(
           (m) => m.HolidaysManagementModule
           ),
         canActivate: [UserGuard],
       },
       {
        path: 'document',
        loadChildren: () =>
          import('./modules/document-management/document-management.module').then(
          (m) => m.DocumentManagementModule
          ),
        canActivate: [UserGuard],
      },

    ],
  },
  {
    path: '**',
    component: ErrorComponent,
    data: { title: 'Error 404' },
    canActivate: [],
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
