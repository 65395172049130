import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { LocationStrategy } from '@angular/common';

import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';
import { Credential } from '../models/credential';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertModuleComponent } from '../modules/shared/alert-module/alert-module.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CompanyService } from '../services/company.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  keyrecaptcha = environment.recaptchaKeyPublic;

  credentials: Credential = new Credential('', '');
  title: string = 'Login';
  choise: string[] = ['Close'];
  message: string = null;
  captcha: string;
  email: string;
  Currentcompany: any;
  selectedLang:any='en';
  imageUrl = environment.baseUrl.split('/api')[0];
  hostname: any;
  connected :boolean = true;
  blacked:boolean=false;

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private router: Router,
    private dialog: MatDialog,
    private location: LocationStrategy,
    private _renderer: Renderer2,
    private _http: HttpClient,
    private companyService: CompanyService
  ) {
 

    $('.bodre').prop('hidden', false);
    $('.loader').prop('hidden', false);
    this.captcha = '';
    this.email = 'mouhamed.charfii@gmail.com';
    if (this.storageService.read('UCA') && this.storageService.read('access')) {
      $('.bodre').prop('hidden', false);
      $('.loader').prop('hidden', false);
      this.authService.autologin().subscribe(res => {
        if (res && res['message'] != 'unauthorized') {
          const user = res['message'].user;
          this.authService.user = user;
          this.credentials = new Credential('', '');
          if (user.privilege_id.name === 'Admin' || user.is_superuser) {
            // $('.bodre').prop('hidden', true);
            // $('.loader').prop('hidden', true);
            this.router.navigate(['/dashboard/admin']);
          } else if (user.privilege_id.name === 'Employee') {
            // $('.bodre').prop('hidden', true);
            // $('.loader').prop('hidden', true);
            this.router.navigate(['/dashboard/employee']);
          }
          else if (user.privilege_id.name === 'Accountant') {
            // $('.bodre').prop('hidden', true);
            // $('.loader').prop('hidden', true);
            this.router.navigate(['/dashboard/accountant']);
          } else {
            // $('.bodre').prop('hidden', true);
            // $('.loader').prop('hidden', true);
            this.router.navigate(['/dashboard/client']);
          }
        }
      },(err)=>{
        this.connected = false;
        $('.bodre').prop('hidden', true);
        $('.loader').prop('hidden', true)
      })
    }else{
      this.connected =false;
      $('.bodre').prop('hidden', true);
      $('.loader').prop('hidden', true);
    }
  }
  @HostListener('window:resize', ['$event'])
onResize(event) {
  if(window.innerWidth <564){
    $('.overlay-container').prop('hidden', true)
    this.blacked = true;
  }else{
    $('.overlay-container').prop('hidden', false)
    this.blacked = false
  }
}

  ngOnInit(): void {
    // if (this.storageService.read('UCA') && this.storageService.read('access')) {
    //   this.authService.autologin().subscribe(res => {
    //     if (res && res['message'] != 'unauthorized') {
    //       const user = res['message'].user;
    //       this.authService.user = user;
    //       this.credentials = new Credential('', '');
    //       if (user.privilege_id.name === 'Admin' || user.is_superuser) {
    //         this.router.navigate(['/dashboard/admin']);
    //       } else if (user.privilege_id.name === 'Employee') {
    //         this.router.navigate(['/dashboard/employee']);
    //       }
    //       else if (user.privilege_id.name === 'Accountant') {
    //         this.router.navigate(['/dashboard/accountant']);
    //       } else {
    //         this.router.navigate(['/dashboard/client']);
    //       }
    //     }
    //   })
    // }else{
      if(localStorage && localStorage.getItem('currentLang')){
        this.selectedLang = localStorage.getItem('currentLang');
      }else{
        this.selectedLang ='en';
      }
      var host = window.location.host;
    this.hostname = window.location.host;
    this.hostname = this.hostname.replace("wwww.", "");
    this.companyService.getCompanybyHost(this.hostname).subscribe(res => {
      this.Currentcompany = res[0];
      if (this.Currentcompany && this.Currentcompany.logo) {
        this.imageUrl += `/logo/default.png`;
        $("#favicon").attr("href", this.imageUrl);
      } else {
        this.imageUrl += `/logo/default.png`;
        $("#favicon").attr("href", this.imageUrl);
      }
    })
    if(window.innerWidth <564 ){
      this.blacked = true;
      $('.overlay-container').prop('hidden', true)
    }else{
      $('.overlay-container').prop('hidden', false)
    }
    // }
  }

  trimmail(){
    this.credentials.password = (this.credentials.password).trim();
  }


  onLogin(form: NgForm) {
    if(window.innerWidth <564 ){
      this.blacked = true;
    }else{
      this.blacked = false;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '20%';
    if (form.valid) {
      if (this.captcha == '') {
        $('.captcha').find('[required]').each(function () {
          $(this).parent().find('.form-error-msg').removeAttr('hidden');
        })

      } else {
        let script = this._renderer.createElement('script')
        // script.defer = true;
        // script.async = true;
        // script.src = "https://www.google.com/recaptcha/api.js";
        this._renderer.appendChild(document.body, script);

        this.authService.verify({ token: this.captcha }).subscribe((res) => {
          if (res['response'] == 'success') {
            this.authService.login(this.credentials).subscribe(
              (resp) => {
                if (resp.user) {
                  this.storageService.write(
                    StorageService.USER_TOKEN_KEY,
                    resp.access
                  );

                  this.storageService.write(
                    StorageService.USER_ACTIVE_PREVIOUS_SESSION,
                    resp.UCA
                  )
                  this.authService.user = resp.user;
                  this.credentials = new Credential('', '');
                  form.reset();
                  if (resp.user.privilege_id.name === 'Admin' || resp.user.is_superuser) {
                    this.router.navigate(['/dashboard/admin']);

                  } else if (resp.user.privilege_id.name === 'Employee') {
                    this.router.navigate(['/dashboard/employee']);
                  }
                  else if (resp.user.privilege_id.name === 'Accountant') {
                    this.router.navigate(['/dashboard/accountant']);
                  } else {
                    this.router.navigate(['/dashboard/client']);
                  }
                } else {
                  alert('Not Authorized ! ');
                }
              },
              (error) => {
                if (!error.error['message']) {
                  error.error['message'] = 'Please verify  email  format';
                }
                this.message = error.error["message"];
                var choix = ['Close'];
                dialogConfig.data = {
                  title: this.title,
                  message: this.message,
                  choise: choix,
                }
                const alertbox = this.dialog.open(AlertModuleComponent, dialogConfig);
              }
            );

          } else {
            return;
          }
        });
      }
    } else {
      this.message = 'Please fill in the address and password fields';
      var choix = ['Close'];
      dialogConfig.data = {
        title: this.title,
        message: this.message,
        choise: choix,
      }
      const alertbox = this.dialog.open(AlertModuleComponent, dialogConfig);
    }
  }
  SignUp() {
    this.router.navigate(['/signup']);
  }
  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
  }

  hide() {
    $('#cookies').hide()

  }
  lang(l){
    this.selectedLang=l;
    localStorage.setItem('currentLang',l);
    window.location.reload();
    
  }
}
