<div class="card card_border border-primary-top p-4">
  <!-- <button (click)="toggleWeekends()">toggle weekends</button> -->
  <full-calendar id="calendar" class="m-5" [options]="calendarOptions" ></full-calendar>

  <div class="row">
  <div class="col-md-6">

  
  <div *ngFor="let item  of this.configuration.ColorCode | keyvalue">

  <b><input type="color" value="{{item.key}}" disabled style="border: 0px;">   </b>     <b>{{item.value}}</b>
  <span required>{{evenement}}</span>
  </div>
</div>

<div class="col-sm-4">
  <input aria-describedby="" class="form-control input-style" placeholder="" id="evenement"
  required  type="text" style="width:50% ; margin-left: 50%; background-color : #ffff;"  disabled/>
</div>
</div>
 
</div>


