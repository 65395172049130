import { Component, OnInit, Inject} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-module',
  templateUrl: './alert-module.component.html',
  styleUrls: ['./alert-module.component.scss']
})


export class AlertModuleComponent implements OnInit {

    
    constructor(
        @Inject(MAT_DIALOG_DATA) public data:{ title: string, 
          message: any, 
          url: string, 
          field:boolean, 
          text:string;
          choise: string[]}
    ) { }

    ngOnInit(): void {
    }

    


}
