import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import { Privilege } from '../models/privilege';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  /**
   * UserService is used to handle Listing
   *
   * Get list users
   * Get user by ID
   *
   *
   */

  constructor(private http: HttpClient) {
  }

  listUsers(offset: number = 0, search = '', limit = 10) {
    return this.http.get(`${environment.baseUrl}users/?offset=${offset}&search=${search}&limit=${limit}`);
  }

  fulllistUsers(offset: number = 0, search = '', limit = 10) {
    return this.http.get(`${environment.baseUrl}users/full/list/of/users/for/list/?offset=${offset}&search=${search}&limit=${limit}`);
  }

  listFilteredUsers(offset: number = 0,  limit = 10,
    first_name = '',
    last_name = '',
    email = '',
    privilege = '',
    position = '',
    
    ) {
    return this.http.get(`${environment.baseUrl}users/get-filtred-users?offset=${offset}&first_name=${first_name}
    &last_name=${last_name}
    &email=${email}
    &privilege=${privilege}
    &position=${position}
    &limit=${limit}`);
  }

  

  userDetail(id: string) {
    return this.http.get(`${environment.baseUrl}users/${id}`);
  }

  createUser(data){
    return this.http.post(`${environment.baseUrl}auth/register`, data);
  }

  updateUser(id: string, data){
    return this.http.put(`${environment.baseUrl}auth/update-user/${id}`, data);
  }

  updateUserRH(id: string, slug:string, data){
    return this.http.put(`${environment.baseUrl}auth/update-user-rh/${id}/${slug}`, data);
  }

  deleteUser(id: string){
    return this.http.delete(`${environment.baseUrl}users/delete/${id}`);
  }

  updateUserPassword(id: string, data){
    return this.http.put(`${environment.baseUrl}auth/update-user-password/${id}`, data)
  }

  confirmUserAccount(id: string, data){
    return this.http.put(`${environment.baseUrl}auth/confirm-account/${id}`, data)
  }


  counterUser(){
    return this.http.get(`${environment.baseUrl}users/user-by-company`);
  }
  UserNotAffectedToCompany(){
    return this.http.get(`${environment.baseUrl}users/notaffected`);
  }
  UserVerificationCode(code){
    return this.http.get(`${environment.baseUrl}users/verifycode/?code=${code}`);
  }

  uploadImageProfil(data, company){
    return this.http.post(`${environment.baseUrl}users/upload-image-profil/${company}`, data);
  }

  updateFilesOfuser(data){
    return this.http.put(`${environment.baseUrl}users/update-folder-of-user-by-name`, data);   
  }

  changeLang(id, lang){
    let data={
      'lang':lang
    }
    return this.http.put(`${environment.baseUrl}users/change-lang/${id}`, data)
  }
 
}
