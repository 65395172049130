import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private storageService: StorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.user$.pipe(
      map(
        (res) => {
          const user = res as User;
          if(!user){
            this.router.navigate(['/']);
            return false;
          }
          if (user.is_active ) {
            return true;
          }
          if (!user.is_active ) {
            this.router.navigate(['/error']);
            return false;
          } 
        },
        (err) => {
          this.storageService.removeAll();
          this.authService.user = null;
          this.router.navigate(['/']);
          return false;
        }
      )
    );
  }
}
