import { AfterViewInit } from '@angular/core';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { Company } from 'src/app/models/company';
import { StorageService } from 'src/app/services/storage.service';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { UserService } from 'src/app/services/user.service';
import { DataService } from "../../data.service";
import { Subject } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';

import { environment } from '../../../environments/environment';


import { state } from '@angular/animations';
import { LocationStrategy } from '@angular/common'
import { delay } from 'rxjs/operators';
declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit {
  loggedUser: User | null = null;
  ActiveCompany : Company | null = null;
  Notificationlist:any;
  Notificationlength: any =0;
  Profil_default:any;
  imageUrl = environment.baseUrl.split('/api')[0];
  imageUrl2 = environment.baseUrl.split('/api')[0];
  selectedLang:any="fr";
  fooSrc: Subject<number>;declare 
   arr  : any[] =[]
   arr1  : any[] =[]
   arr2  : any[] =[]

   public fooValueSecond: number = 90;



  constructor(
    private userService: UserService,
    private companyService: CompanyService,
    private authService: AuthService, 
    private notificationService: NotificationService,
    private router: Router, 
    public dataService: DataService,
    private storageservice: StorageService,
    private location: LocationStrategy
    ) {
      this.fooSrc = this.dataService.fooSource;
      this.dataService._fooSource.subscribe(value => {
        console.log(value);
      });
      if(localStorage.getItem('currentLang')&& localStorage.getItem('currentLang')=="en"){
        this.selectedLang = "en";
        
      }

      if(localStorage.getItem('currentLang')&& localStorage.getItem('currentLang')=="fr"){
        this.selectedLang = "fr";
      }
     }

  ngOnInit(): void {
    this.authService.user$.subscribe((user) => {
      this.loggedUser = user;
      this.userService.userDetail(this.loggedUser._id).subscribe(details =>{
        this.imageUrl += `/logo/`+details['company_id']['logo'];
        $("#favicon").attr("href",this.imageUrl);

        this.imageUrl2 += `/data/`+details['company_id']['slug']+`/profil/`
      if(this.loggedUser.profil_img == 'false'){
        this.Profil_default = "219983.png";
        }else{
          this.Profil_default = this.loggedUser.profil_img;
        }

        let dte = new Date();
        let dte1 = new Date();
        dte.setDate(dte.getDate() - 1);
        dte1.setHours(0,0,0,0);
        dte.setHours(0,0,0,0);
      this.notificationService.NotificationListLimited(this.loggedUser._id).subscribe(notificationList =>{
        this.Notificationlist = notificationList;
        
        for(let j in notificationList){
          let date1= new Date(notificationList[j].createdAt)
          if(date1>dte1){
            this.arr.push(notificationList[j])

          }
          if(date1<dte1 && date1>dte){
            this.arr1.push(notificationList[j])
            
          }
          if(date1 < dte){
            this.arr2.push(notificationList[j])
          
          }
          if(notificationList[j].read == false){
            this.Notificationlength += 1;
          }
        }
        
      })
      })
      this.dataService._fooSource.subscribe(value => {
        if(value == 90 ){
          this.notificationService.NotificationListLimited(this.loggedUser._id).subscribe(notificationList =>{
            this.Notificationlist = notificationList;
            let dte = new Date();
            let dte1 = new Date();
            dte.setDate(dte.getDate() - 1);
            dte1.setHours(0,0,0,0);
            dte.setHours(0,0,0,0);
            this.arr=[]
            this.arr1=[]
            this.arr2=[]
            for(let j in notificationList){
              let date1= new Date(notificationList[j].createdAt)
              if(date1>dte1){
                this.arr.push(notificationList[j])
    
              }
              if(date1<dte1 && date1>dte){
                this.arr1.push(notificationList[j])
                
              }
              if(date1 < dte){
                this.arr2.push(notificationList[j])
              
              }
              if(notificationList[j].read == false){
                this.Notificationlength += 1;
              }
            }
            this.Notificationlength += 1;           
          })
          this.changeFoo(0)
        }
      });
    });
  }
  logout() {
    this.storageservice.remove("access");
    this.storageservice.remove("UCA");
    // this.storageservice.remove("_grecaptcha")
      
      this.router.ngOnDestroy();
      
        history.pushState(null, null, window.location.href);
        // check if back or forward button is pressed.
        this.location.onPopState(() => {
            //history.pushState(null, null, window.location.href);
            window.history.forward();
        });
        this.router.navigate(['/']);
        

  }

  ngAfterViewInit(): void {
    $('.menu-list > a').click(function () {
      var parent = jQuery(this).parent();
      var sub = parent.find('> ul');

      if (!jQuery('section').hasClass('sidebar-menu-collapsed')) {
        if (sub.is(':visible')) {
          sub.slideUp(200, function () {
            parent.removeClass('nav-active');
            jQuery('.main-content').css({
              height: '',
            });
            mainContentHeightAdjust();
          });
        } else {
          visibleSubMenuClose();
          parent.addClass('nav-active');
          sub.slideDown(200, function () {
            mainContentHeightAdjust();
          });
        }
      }
      return false;
    });

    function visibleSubMenuClose() {
      jQuery('.menu-list').each(function () {
        var t = jQuery(this);
        if (t.hasClass('nav-active')) {
          t.find('> ul').slideUp(200, function () {
            t.removeClass('nav-active');
          });
        }
      });
    }

    function mainContentHeightAdjust() {
      // Adjust main content height
      var docHeight = jQuery(document).height();
      if (docHeight > jQuery('.main-content').height())
        jQuery('.main-content').height(docHeight);
    }

    //  class add mouse hover
    $('.custom-nav > li').hover(
      function () {
        jQuery(this).addClass('nav-hover');
      },
      function () {
        jQuery(this).removeClass('nav-hover');
      }
    );

    //  class add mouse hover
    $('.mail-nav > li').hover(
      function () {
        jQuery(this).addClass('nav-hover');
      },
      function () {
        jQuery(this).removeClass('nav-hover');
      }
    );

    // Menu Toggle
    $('.toggle-btn').click(function () {
      $('.sidebar-menu').getNiceScroll().hide();

      if ($('section').hasClass('sidebar-menu-collapsed')) {
        $('.sidebar-menu').getNiceScroll().hide();
      }
      var body = $('section');
      var bodyposition = body.css('position');

      if (bodyposition != 'relative') {
        if (!body.hasClass('sidebar-menu-collapsed')) {
          body.addClass('sidebar-menu-collapsed');
          $('.custom-nav ul').attr('style', '');

          jQuery(this).addClass('menu-collapsed');
        } else {
          body.removeClass('sidebar-menu-collapsed chat-view');
          $('.custom-nav li.active ul').css({
            display: 'block',
          });
          jQuery(this).removeClass('menu-collapsed');
        }
      } else {
        if (body.hasClass('sidebar-menu-show'))
          body.removeClass('sidebar-menu-show');
        else body.addClass('sidebar-menu-show');

        mainContentHeightAdjust();
      }
    });

    searchform_reposition();

    jQuery(window).resize(function () {
      if (jQuery('body').css('position') == 'relative') {
        jQuery('body').removeClass('sidebar-menu-collapsed');
      } else {
        $('section').css({
          left: '',
          marginRight: '',
        });
      }

      searchform_reposition();
    });

    function searchform_reposition() {
      if ($('.searchform').css('position') == 'relative') {
        $('.searchform').insertBefore('.sidebar-menu-inner .logged-user');
      } else {
        $('.searchform').insertBefore('.menu-right');
      }
    }


    /************** Search ****************/
    $(function () {
      var button = $('#loginButton');
      var box = $('#loginBox');
      var form = $('#loginForm');
      button.removeAttr('href');
      button.mouseup(function (login) {
        box.toggle();
        button.toggleClass('active');
      });
      form.mouseup(function () {
        return false;
      });
      $(this).mouseup(function (login) {
        if (!($(login.target).parent('#loginButton').length > 0)) {
          button.removeClass('active');
          box.hide();
        }
      });
    });
  }

  lang(l){
    this.selectedLang=l;
    // if(this.loggedUser && this.loggedUser.lang != l){
    //   this.userService.changeLang(this.loggedUser._id, l).subscribe((res)=>{
    //   },(err)=>{console.log(err)})
    // }
    localStorage.setItem('currentLang',l);
    window.location.reload();
  }
  MoreNotification(){
    
  }


  RedirectMe(item){
    if(item.read == true){
      if(item.type == "Holiday"){
        this.router.navigate(['/dashboard/absences/'+item.source])
        return;
      }
      if(item.type == "refund"){
        this.router.navigate(['/dashboard/refunds/details/'+item.source])
        return;
      }
      if(item.type == "Document"){
        this.router.navigate(['/dashboard/document/update/'+item.source])
        return;
      }
      else{
        this.router.navigate(['/dashboard/absences/'+item.source])
        return;
      }
    }else{
      this.Markasread(item)
          if(item.type == "Holiday"){
            this.router.navigate(['/dashboard/absences/'+item.source])
            return;

          }
          if(item.type == "refund"){
            this.router.navigate(['/dashboard/refunds/details/'+item.source])
            return;
          }
          if(item.type == "Document"){
            this.router.navigate(['/dashboard/document/'+item.source])
            return;
          }
          else{
            this.router.navigate(['/dashboard/absences/'+item.source])
            return;
          }
     
    }
  
  
  }
  readall(){
    this.notificationService.notificationReadAll(this.loggedUser._id).subscribe((res)=>{
      this.Notificationlist = (this.Notificationlist).map(x => {
        x.read = true;
        return x;
      });
      this.Notificationlength = 0;
      //  $('#notifliste').load('  #notifliste')
    // window.location.reload();
      return;
    })
  }
  Markasread(item){
    this.changeFoo(1000);
    this.notificationService.notificationRead(item._id).subscribe((result) =>{
      let indexReaden = this.Notificationlist.findIndex(x => (x._id).toString() === (item._id).toString())
      this.Notificationlist[indexReaden]['read'] = true;
      this.Notificationlength -=1 ;
      return;
      })
  }
  Markasnotread(item){
    this.changeFoo(1000);
    this.notificationService.notificationUnRead(item._id).subscribe((result) =>{
      let indexReaden = this.Notificationlist.findIndex(x => (x._id).toString() === (item._id).toString())
      this.Notificationlist[indexReaden]['read'] = false;
      this.Notificationlength +=1 ;
      return;
      })
  }

  changeFoo(x) {
    this.dataService.changeFoo(x);
  }
}
