import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarComponent } from './calendar/calendar.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import timeGridPlugin from '@fullcalendar/timegrid';
import {FormsModule} from "@angular/forms";
import { AlertModuleComponent } from "./alert-module/alert-module.component";
import {MatDialogModule} from '@angular/material/dialog';



/** Integration of FullCalendarModule */
FullCalendarModule.registerPlugins([ // Register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin,
  bootstrapPlugin
]);


@NgModule({
    declarations: [
        CalendarComponent, 
        AlertModuleComponent,
    ],
    imports: [
        CommonModule,
        FullCalendarModule,
        FormsModule,
        MatDialogModule,
    ],
    exports: [
        CalendarComponent,
        AlertModuleComponent,
        MatDialogModule,
    ]
})
export class SharedModule { }
