<div class="container-fluid">

  <div class="row" id="informationCompanyRequest">



    <div class="col-lg-6">
        <!-- <div class="col-md-6" style="margin-bottom: 40%;"></div> -->
        <div *ngIf="step == 2" class="offset-3" style="padding-top: 25rem;">
          <div class="row justify-content-center" style="padding-bottom:2rem;" >
            <div class="col-xl-3" >
              <div class="square">
                <a class="fa fa-check fa-3x" style="margin-top: 25%;margin-left: 25%; color: #87D0F3;"></a>
              </div>
            </div>
            <div class="col-xl-9" style="align-self: center;">
              <div class="textleft">
                User informations
              </div>
            
            </div>
            
            <!-- <div class="square"><a class="fa fa-check" style="display: block; color: #74c3d7;"></a> </div> <h4 class="textleft"> User information</h4> -->

          </div>
          <div class="row justify-content-center" style="padding-bottom:2rem;" >
            <div class="col-xl-3" >
              <div class="square2">
                <a   style="position: absolute; margin-top: 12%;margin-left: 14%; color: #ffffff;" class="textleft">2 </a>
              </div>
            </div>
            <div class="col-xl-9" style="align-self: center;">
              <div class="textleft">
                Company informations
              </div>
            
            </div>
            
            <!-- <div class="square"><a class="fa fa-check" style="display: block; color: #74c3d7;"></a> </div> <h4 class="textleft"> User information</h4> -->

          </div>

        </div>
        <div  *ngIf="step == 1" class="offset-3" style="padding-top: 25rem;" > 
          <div class="row justify-content-center" style="padding-bottom:2rem;" >
            <div class="col-xl-3" >
              <div class="square">
                <a  style="position: absolute; margin-top: 12%;margin-left: 14%; color: #87D0F3; font-size: xx-large;">1</a>
              </div>
            </div>
            <div class="col-xl-9" style="align-self: center;">
              <div class="textleft">
                User informations
              </div>
            
            </div>
            
            <!-- <div class="square"><a class="fa fa-check" style="display: block; color: #74c3d7;"></a> </div> <h4 class="textleft"> User information</h4> -->

          </div>
          <div class="row justify-content-center" style="padding-bottom:2rem;" >
            <div class="col-xl-3" >
              <div class="square2">
                <a style="position: absolute; margin-top: 12%;margin-left: 14%; color: #ffffff;" class="textleft"> 2</a>
              </div>
            </div>
            <div class="col-xl-9" style="align-self: center;">
              <div class="textleft">
                Company informations
              </div>
            
            </div>
            
            <!-- <div class="square"><a class="fa fa-check" style="display: block; color: #74c3d7;"></a> </div> <h4 class="textleft"> User information</h4> -->

          </div>
          </div>
          <!-- <img src="{{imageUrl}}" alt="Voguel Consulting" title="logo" class="img-fluid"
            style="width:90%;float: revert;margin-top: 30%; text-align:center; background-color: transparent; " />
          <img src="{{imageUrl}}" alt="Hotel CaravanSérail" title="logo" class="img-fluid"
            style="width:90%;float: revert;margin-top: 30%; text-align:center; background-color: transparent; " /> -->
      
    </div>

    <div class="col-md-6 " style="background: #ffffff;">
      <div class="login d-flex align-items-center " style="background-color: #ffffff;">
        <div *ngIf="step == 1" class="col-lg-12 col-xl-7 mx-auto" style="margin-bottom: 20px; margin-top: -10rem;">
          <h1 class="display-4 text-center logintext">User registration form</h1>
          <br><br><br>
          <form #companyForm="ngForm" (ngSubmit)="onSubmit(companyForm)">
            <div class="form-group mb-3">
              <input name="firstname" [(ngModel)]="user.first_name" type="text" id="first_name" placeholder="First name"
                class="form-control rounded-pill border-3  px-4  " style="height: 3rem;" required />
                <div class="form-error-msg" hidden>
                  <span class="error-text">This field is required</span>
                </div>

            </div>
            <br>
          
            <div class="form-group mb-3">
              <input name="lastname" [(ngModel)]="user.last_name" type="text" id="last_name" placeholder="Last name"
                class="form-control rounded-pill border-3  px-4  " style="height: 3rem;" required />
              <div class="form-error-msg" hidden>
                <span class="error-text">This field is required</span>
              </div>
            </div>
            <br>
            <div class="form-group mb-3">
              <input name="cin" [(ngModel)]="user.cin" type="text" id="cin" placeholder="Cin"
                class="form-control rounded-pill border-3  px-4  " style="height: 3rem;" required />
              <div class="form-error-msg" hidden>
                <span class="error-text">This field is required</span>
              </div>
            </div>
            <br>
            <div class="form-group mb-3">
              <input name="birth_date" type="date" max="{{today}}" id="birth_date" 
                class="form-control rounded-pill border-3  px-4  " style="height: 3rem;" required />
              <div class="form-error-msg" hidden>
                <span class="error-text">This field is required</span>
              </div>
            </div>
            <br>
            <div class="form-group mb-3">
              <input name="email" [(ngModel)]="user.email" type="email" id="email" placeholder="Email"
                class="form-control rounded-pill border-3  px-4  " style="height: 3rem;" required />
              <div class="form-error-msg" hidden>
                <span class="error-text">This field is required</span>
              </div>
            </div>
            <br>
            <div class="form-group mb-3">
              <select name="genre" [(ngModel)]="user.genre" type="text" id="genre" placeholder="genre"
                class="custom-select rounded-pill border-3  px-4  " style="height: 3rem;" required >
                <option value="MALE">{{'Male' | translate}}</option>
                <option value="FEMALE">{{'Female' | translate}}</option>
                <option value="NOT SPECIFIED">{{'Nonmale' | translate}}</option>
              </select>
              <div class="form-error-msg" hidden>
                <span class="error-text">This field is required</span>
              </div>
            </div>
            <br>

            <div class="form-group mb-3">
              <ngx-intl-tel-input [cssClass]="'custom'"
                [preferredCountries]="[CountryISO.Tunisia, CountryISO.UnitedKingdom]" [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true" [searchCountryFlag]="true" [maxLength]="12"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" id="phonenumber"
                [selectedCountryISO]="CountryISO.Tunisia" [phoneValidation]="true" name="phone"
                [(ngModel)]="user.phone_number" class="form-control rounded-pill border-3  px-4  "
                style="height: 3rem;">
              </ngx-intl-tel-input>
              <div class="form-error-msg" hidden>
                <span class="error-text">This field is required</span>
              </div>
            </div>
            <br>

            <div class="from-group ml-1">

             
              <button *ngIf="step == 2" (click)="Previous()" type="button" class="btn btn-primary btn-style mt-4 float-right"
                style="margin-right:5px; border-color:#cbd4d8; background-color: #cbd4d8;height: 3.5rem; border-radius: 15px;">Previous</button>
              <button *ngIf="step == 1" (click)="Next()" type="button" class="btn btn-primary btn-style mt-4 float-right"
                style="background-color: #87D0F3 ; color: #FFFF;height: 3.5rem; border-radius: 15px;">Next</button>
                <button *ngIf="step == 1" routerLink="/" type="button" class="btn btn-primary btn-style mt-4 float-right"
                style="margin-right: 5px; border-color:#cbd4d8 ; background-color: #cbd4d8;height: 3.5rem; border-radius: 15px;">Cancel</button>
              <button *ngIf="step == 2" type="submit" class="btn  btn-style float-right"
                style="margin-left:5px ; background-color: #87D0F3 ;  color: #FFFF; height: 3.5rem; border-radius: 15px;">Submit form</button>
            </div>


          </form>
          <br>




          <!-- </div> -->


        </div>
        <div *ngIf="step == 2" class="col-lg-12 col-xl-7 mx-auto" style="margin-bottom: 20px; margin-top: -10rem;">
          <h1 class="display-4 text-center logintext">Company registration form</h1>
          <br><br><br>
          <form #companyForm="ngForm" (ngSubmit)="onSubmit(companyForm)">
            <div class="form-group mb-3">
              <input [(ngModel)]="company.name" name="name" type="text" id="name" placeholder="Company name"
                class="form-control rounded-pill border-3  px-4  " style="height: 3rem;" required />
                <div class="form-error-msg" hidden>
                  <span class="error-text">This field is required</span>
                </div>

            </div>
            <br>
            
            <div class="form-group mb-3">
              <input type="file" accept="image/png, image/jpeg" id="logo" placeholder="Logo"
                class="form-control rounded-pill border-3  px-4 " style="height: 4rem;"
                style="font-size: 15px; overflow:hidden; height: 150%;" required />
              <div class="form-error-msg" hidden>
                <span class="error-text">This field is required</span>
              </div>
            </div>
            <br>
            <div class="form-group mb-3">
              <select [(ngModel)]="company.domain" name="domain" class="custom-select rounded-pill border-3  px-4 " id="domain"
                type="text" required>
                <option *ngFor="let t of types" value="{{t}}">{{t}}</option>
              </select>
              <div class="form-error-msg" hidden>
                <span class="error-text">This field is required</span>
              </div>
            </div>
            <br>
            <div class="form-group mb-3">
              <input [(ngModel)]="company.tax_ID" name="tax" type="text" id="tax" placeholder="Tax Id"
                class="form-control rounded-pill border-3  px-4  " style="height: 3rem;" required />
              <div class="form-error-msg" hidden>
                <span class="error-text">This field is required</span>
              </div>
            </div>
            <br>
            <div class="form-group mb-3">
              <input [(ngModel)]="company.commercial_register" name="tax" type="text" id="regiter" placeholder="commercial register number"
                class="form-control rounded-pill border-3  px-4  " style="height: 3rem;" required />
              <div class="form-error-msg" hidden>
                <span class="error-text">This field is required</span>
              </div>
            </div>
            <br>
            <div class="form-group mb-3">
              <input [(ngModel)]="company.address_street" name="street" type="text" id="CompanyAdress" placeholder="Address"
                class="form-control rounded-pill border-3  px-4  " style="height: 3rem;" required />
              <div class="form-error-msg" hidden>
                <span class="error-text">This field is required</span>
              </div>
            </div>
            <br>

            <div class="form-group mb-3">
              <input [(ngModel)]="company.address_city" name="country" type="text" id="country" placeholder="Country"
                class="form-control rounded-pill border-3  px-4  " style="height: 3rem;" required />

              <div class="form-error-msg" hidden>
                <span class="error-text">This field is required</span>
              </div>
            </div>
            <br>

            <div class="from-group ml-1">

              <div class="from-group ml-1">
                <div class="g-recaptcha" data-sitekey="12345" data-size="invisible" data-badge="inline">
                  <div class="captcha">
                    <script src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit" async defer></script>
                    <re-captcha (resolved)="resolved($event)" siteKey={{keyrecaptcha}} required></re-captcha>


                    <div class="form-error-msg" hidden>
                      <span class="error-text">This field is required</span>
                    </div>
                  </div>
                  <div [hidden]="!captcha">
                  </div>
                </div>

              </div>
            </div>
            <div style="margin-left:10rem;">
              <button *ngIf="step == 1" routerLink="/" type="button" class="btn btn-primary btn-style mt-4 float-right"
                style="margin-right: 5px; border-color:#cbd4d8 ; background-color: #cbd4d8;">Cancel</button>
              
              <button *ngIf="step == 1" (click)="Next()" type="button" class="btn btn-primary btn-style mt-4 float-right"
                style="background-color: #87D0F3 ; color: #FFFF;height: 3.5rem; border-radius: 15px;">Next</button>
              <button *ngIf="step == 2" type="submit" class="btn btn-primary btn-style mt-4 float-right"
                style="margin-left:5px ; background-color: #87D0F3 ;  color: #FFFF;height: 3.5rem; border-radius: 15px;">Submit form</button>
                <button *ngIf="step == 2" (click)="Previous()" type="button" class="btn btn-primary btn-style mt-4 float-right"
                style="margin-right: 5px; border-color:#cbd4d8 ; background-color: #cbd4d8;height: 3.5rem; border-radius: 15px; ">Previous</button>
              <!-- <button type="submit" class="btn btn-primary btn-block" style="height: 3rem; width: 60%; border-radius: 10px;">Sign
              in</button> -->
            </div>

          </form>
          <br>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>

</div>

<!-- <section class="h-100" style="background: #78c7db;">
  
    <div class="row">
      <div class="col">
        <div class="card" id="informationCompanyRequest">
          <div class="row ">
            <div class="col-xl-6 d-none d-xl-block" style="background: #78c7db;"> -->
<!-- <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/img4.webp"
                alt="Sample photo" class="img-fluid"
                style="border-top-left-radius: .25rem; border-bottom-left-radius: .25rem;" /> -->
<!-- </div>
            <div class="col-xl-6">
              <div class="card-body p-md-5 text-black">
                <form #companyForm="ngForm" (ngSubmit)="onSubmit(companyForm)">
                  <div *ngIf="step == 1" >
                <h3 class="mb-9" style="text-align: center; font-size: 34px;">User registration form</h3>
                <br><br>
                <div class="form-outline mb-4">
                  <label class="form-label" for="first_name">First name</label>
                  <input name="firstname" [(ngModel)]="user.first_name" type="text" id="first_name" class="form-control form-control-lg" required/>
                  <div class="form-error-msg" hidden>
                    <span class="error-text">This field is required</span>
                 </div>
                  
                </div>
                <div class="form-outline mb-4">
                  <label class="form-label" for="last_name">Last name</label>
                  <input  name="lastname" [(ngModel)]="user.last_name"  type="text" id="last_name" class="form-control form-control-lg" required/>
                  <div class="form-error-msg" hidden>
                    <span class="error-text">This field is required</span>
                 </div>
                  
                </div>

                <div class="form-outline mb-4">
                  <label class="form-label" for="cin">CIN</label>
                  <input  name="cin" [(ngModel)]="user.cin" type="text" id="cin" class="form-control form-control-lg" required/>
                  <div class="form-error-msg" hidden>
                    <span class="error-text">This field is required</span>
                 </div>
                  
                </div>

                <div class="form-outline mb-4">
                  <label class="form-label" for="cin">Birth date</label>
                  <input  name="birth_date"  type="date" max="{{today}}"  id="birth_date" class="form-control form-control-lg" required/>
                  <div class="form-error-msg" hidden>
                    <span class="error-text">This field is required</span>
                 </div>
                  
                </div>
                <div class="form-outline mb-4">
                  <label class="form-label" for="email">Email</label>
                  <input name="email"  [(ngModel)]="user.email" type="email" id="email" class="form-control form-control-lg" required/>
                  <div class="form-error-msg" hidden>
                    <span class="error-text">This field is required</span>
                 </div>
                  
                </div>

                 <div class="form-outline mb-4">
                  <label class="form-label" for="phonenumber">Phone number</label>
                  <br>
                    <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="[CountryISO.Tunisia, CountryISO.UnitedKingdom]"
                      [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true" [maxLength]="12"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" id="phonenumber"
                      [selectedCountryISO]="CountryISO.Tunisia" [phoneValidation]="true" name="phone"  [(ngModel)]="user.phone_number" class="form-control form-control-lg" >
                    </ngx-intl-tel-input>
                    <div class="form-error-msg" hidden>
                      <span class="error-text">This field is required</span>
                   </div>
                 </div> -->



<!-- <div class="form-outline mb-4">
                  <label class="form-label" for="phonenumber">Phone number</label>
                  <ngx-intl-tel-input [maxLength]="15"  [separateDialCode]="false" [phoneValidation]="true" name="phone"  [(ngModel)]="user.phone_number" id="phonenumber" class="form-control-lg"  required ></ngx-intl-tel-input>
                  <div class="form-error-msg" hidden>
                    <span class="error-text">This field is required</span>
                 </div>
                 
                </div> -->
<!-- </div> -->
<!-- Comopany information-->
<!-- <div *ngIf="step == 2">
                <h3 class="mb-5 text-uppercase">Company registration form</h3>
                <div class="form-outline mb-4">
                  <label class="form-label" for="name">Company name</label>
                  <input [(ngModel)]="company.name" name="name"type="text" id="name" class="form-control form-control-lg"  required/>
                  <div class="form-error-msg" hidden>
                    <span class="error-text">This field is required</span>
                 </div>
                  
                </div>
                <div class="form-outline mb-4">
                  <label class="form-label" for="logo">Company  logo</label>
                  <input  type="file" accept="image/png, image/jpeg" id="logo" class="form-control form-control-lg"
                  style="font-size: 15px; overflow:hidden; height: 100%;" required/>
                  <div class="small text-muted mt-2">Upload your Logo. Max file size 50 MB</div>
                    <div class="form-error-msg" hidden>
                      <span class="error-text">This field is required</span>
                  </div>
                </div>
            
                  <div class="form-outline mb-4">
                  <label class="form-label" for="domain">Company Domain</label>
            
                  <select [(ngModel)]="company.domain" name="domain" class="form-control input-style" id="domain"  type="text" required>
                    <option *ngFor="let t of types" value="{{t}}">{{t}}</option>
                  </select>
                    <div class="form-error-msg" hidden>
                      <span class="error-text">This field is required</span>
                  </div>
                </div>  
                <div class="form-outline mb-4">
                  <label class="form-label" for="tax">Company tax identificator</label>
                  <input  [(ngModel)]="company.tax_ID" name="tax" type="text" id="tax" class="form-control form-control-lg" required/>
                  <div class="form-error-msg" hidden>
                    <span class="error-text">This field is required</span>
                 </div>
                 
                </div>

                <div class="form-outline mb-4">
                  <label class="form-label" for="CompanyAdress">Company address</label>
                  <input [(ngModel)]="company.address_street" name="street" type="text" id="CompanyAdress" class="form-control form-control-lg" required/>
                  <div class="form-error-msg" hidden>
                    <span class="error-text">This field is required</span>
                 </div>
                  
                </div>

                <div class="form-outline mb-4">
                  <label class="form-label" for="country">Country</label>
                  <input [(ngModel)]="company.address_city" name="country" type="text" id="country" class="form-control form-control-lg" required/>
                  <div class="form-error-msg" hidden>
                    <span class="error-text">This field is required</span>
                 </div>
                  
                </div>
              </div>
              <div class="d-flex justify-content-end pt-3">
                <button *ngIf="step == 1" routerLink="/" type="button"  class="btn btn-primary btn-style mt-4" style="margin-right: 5px; border-color:#cbd4d8 ; background-color: #cbd4d8;">Cancel</button>
                <button *ngIf="step == 2" (click)="Previous()" type="button"  class="btn btn-primary btn-style mt-4" style="margin-right:5px; border-color:#cbd4d8; background-color: #cbd4d8;">Previous</button>
                <button *ngIf="step == 1" (click)="Next()" type="button" class="btn btn-primary btn-style mt-4" style="background-color: #87D0F3 ; color: #FFFF;">Next</button>
                <button *ngIf="step == 2"  type="submit"  class="btn btn-primary btn-style mt-4"style="margin-left:5px ; background-color: #87D0F3 ;  color: #FFFF;">Submit form</button>
              </div>
              </form>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</section> -->