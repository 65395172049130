import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageService } from '../services/storage.service';
import {Privilege} from '../models/privilege';

@Injectable({
  providedIn: 'root',
})
export class AdminRHaccountantGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private storageService: StorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.user$.pipe(
      map(
        (res) => {
          const user = res as User;
          var check_prev = false;
          if(user && user.privilege_id.name){
            if(user.privilege_id.name == 'Admin'){
              check_prev =true;
            }else if(user.privilege_id.name =="RH"){
              check_prev =true;
            }else if(user.privilege_id.name =="Accountant"){
              check_prev =true;
            }
          }
          if(!user){
            this.router.navigate(['/error']);
            return false;
          }
          if (user && (user.is_superuser == false && (user.privilege_id && (check_prev == false))) ) {
            this.router.navigate(['/error']);
            return false;
          }
          if (user && (user.is_superuser == true || (user.privilege_id && (check_prev == true))) ) {
            return true;
          }
         
        },
        (err) => {
          this.storageService.removeAll();
          this.authService.user = null;
          this.router.navigate(['/error']);
          return false;
        }
      )
    );
  }
}
