<div class="bodre" hidden="true">
  <div class="loader" hidden="true">
    <style>
      /* Safari */
      @-webkit-keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
        }

        100% {
          -webkit-transform: rotate(360deg);
        }
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    </style>
  </div>
</div>
<br><br><br><br><br><br><br><br><br><br>
<div *ngIf="connected==false" class="container" id="container">

  <div class="form-container sign-in-container "  [ngClass]="{'form-container sign-in-container fully':blacked, 'form-container sign-in-container':!blacked}">
    
    <form #loginForm="ngForm" (ngSubmit)="onLogin(loginForm)">
      
      <div class="row" style="    width: -webkit-fill-available">
        <h1 class="col-9"  style="color: #868579 !important;">{{'shared_18' | translate}}</h1>
        <li class="dropdown col-2" style="list-style: none;">
          <!-- <a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false" disable> -->
            <a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false"  disable>
            <i style="opacity: 100 !important;"> <img *ngIf="selectedLang == 'fr'" src="/assets/images/fr.svg" alt="" style="cursor: pointer; height: 2rem;"> <img *ngIf="selectedLang == 'en'" src="/assets/images/uk.svg" alt="" style="cursor: pointer; height: 2rem;"> </i></a>
          <ul class="dropdown-menu">
            
            <li *ngIf="selectedLang == 'en'">
                <div class="flag_img" (click)="lang('fr')" style="cursor: pointer;"><img src="/assets/images/fr.svg" alt="fr" style="cursor: pointer;"> </div>
                
              </li>
            <li  *ngIf="selectedLang == 'fr'" class="odd">
                <div class="flag_img" (click)="lang('en')" style="cursor: pointer;"><img src="/assets/images/uk.svg" alt="en" style="cursor: pointer;"> </div>
                
              </li>
          </ul>
        </li>
      </div>
      <div class="row" *ngIf="blacked">
        <img class="img-fluid" src="{{imageUrl}}" alt="" width="250px" height="200px"> 
      </div>

      
      <div class="social-container">
        <a href="/" class="social"><i class="bi bi-circle-fill" style="color: #CAEBFF;opacity: 50% !important;"></i></a>
        <a href="/" class="social"><i class="bi bi-circle-fill" style="color: #CAEBFF;opacity: 70% !important;"></i></a>
        <a href="/" class="social"><i class="bi bi-circle-fill"
            style="color: #CAEBFF;opacity: 100% !important;"></i></a>
      </div>

      <input *ngIf="selectedLang == 'en'" id="inputEmail" type="email" placeholder="Email address"
        [(ngModel)]="credentials.email" (change)="trimmail()"  name="email" required autofocus="" class="form-control">
      <input *ngIf="selectedLang == 'fr'" id="inputEmail" type="email" placeholder="Adresse mail"
        [(ngModel)]="credentials.email" (change)="trimmail()" name="email" required autofocus="" class="form-control">


      <input *ngIf="selectedLang == 'en'" id="inputPassword" type="password" placeholder="Password" required
        [(ngModel)]="credentials.password" name="password" class="form-control">
      <input *ngIf="selectedLang == 'fr'" id="inputPassword" type="password" placeholder="Mot de passe" required
        [(ngModel)]="credentials.password" name="password" class="form-control">

        <div *ngIf="selectedLang == 'en'" style="text-align: right;"><a
          routerLink="/resetpassword" class="fw-bold" style="color:rgb(0, 163, 228);">Reset password?</a>
      </div>
      <div *ngIf="selectedLang == 'fr'" style="text-align: right;"><a
        routerLink="/resetpassword" class="fw-bold" style="color:rgb(0, 163, 228);">Réinitialiser le mot de passe ?</a></div>


        


      <br>
      <div class="from-group ml-1">
        <div class="g-recaptcha" data-sitekey="12345" data-size="invisible" data-badge="inline">
          <div class="captcha">
            <script src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit" async
              defer></script>
            <re-captcha (resolved)="resolved($event)" siteKey={{keyrecaptcha}} required></re-captcha>


            <div class="form-error-msg" hidden>
              <span class="error-text" style="font-size:large ;">{{'Error_field' | translate}}</span>
            </div>
          </div>
          <div [hidden]="!captcha">
          </div>
        </div>

      </div>
      <br>
      <button style="color: #868579;">{{'Btn_sign_in' | translate}}</button>
      <br>
      <br>
      <span *ngIf="selectedLang == 'en'" class="fw-normal"
        style="font-size: 1rem; text-align: center;color: darkgray;  ">Not registered? <a
          routerLink="/signup" class="fw-bold" style="color:rgb(0, 163, 228);">Create account</a></span>
      <span *ngIf="selectedLang == 'fr'" class="fw-normal"
        style="font-size: 1rem; text-align: center;color: darkgray; ">Non enregistré? <a
          routerLink="/signup" class="fw-bold" style="color:rgb(0, 163, 228);">Créer un compte</a></span>

    </form>
  </div>
  <div class="overlay-container">
    <div class="overlay">

      <div class="overlay-panel overlay-right">
        <img class="img-fluid" src="{{imageUrl}}" alt="" width="250px" height="200px">
        <br>
        <p style="color: #888; ">{{'shared_19'| translate}}</p>
      </div>
    </div>
  </div>
</div>
<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css'>