import { Component, OnInit, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service'
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertModuleComponent } from '../modules/shared/alert-module/alert-module.component';
import { TranslateService } from "@ngx-translate/core";



declare var $: any;

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  keyrecaptcha = environment.recaptchaKeyPublic;

  selectedLang: any = 'en';
  step1: boolean = true;
  step2: boolean = false;
  step3: boolean = false;
  step4: boolean = false;
  registerForm: FormGroup; // For reset password request
  changingForm: FormGroup; // Change password  form
  submitted: boolean = false;
  submitted2: boolean = false;
  /**For recaptcha on first  submit */
  wrongsubmit1: number = 0;
  activaterecaptcha1:boolean = false;
  captcha: string='';
  /** For recaptcha on seconf submit */
  wrongsubmit2: number = 0;
  activaterecaptcha2:boolean = false;
  captcha2: string='';
  match: boolean = true; //Password matching
  code: any; // Code added
  codeverification: boolean = true; // Verification of code existance
  imageUrl = environment.baseUrl.split('/api')[0] + '/logo/default.png';

  constructor(
    private authservice: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private _renderer: Renderer2,
    private translate : TranslateService,
    private formBuilder: FormBuilder
  ) {
    if (localStorage && localStorage.getItem('currentLang') == 'fr') {
      this.selectedLang = 'fr';
    } else if (localStorage && localStorage.getItem('currentLang') == 'en') {
      this.selectedLang = 'en';
    } else {
      localStorage.setItem('currentLang', 'en');
      this.selectedLang = 'en';
    }
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    });
    this.changingForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"
      )]],
      confirmat: ['', [Validators.required]],
    });

    if (this.route.snapshot.queryParams && this.route.snapshot.queryParams['token']) {
      this.step1 = false;
      this.step2 = false;
      this.step3 = false;
      this.step4 = true;
      this.checkLink(this.route.snapshot.queryParams['token'], this.route.snapshot.queryParams['id']);
    }
  }

  matchPassword() {
    const password = this.changingForm.controls["password"].value;
    const confirm = this.changingForm.controls["confirmat"].value;
    if (password != confirm) {
      this.match = false;
      this.toaster.error(this.translate.instant('Profil-error5'))
      return this.match
    }
    this.match = true;
    return (this.match)
  }

  get f() { return this.registerForm.controls; }
  get g() { return this.changingForm.controls; }


  lang(l) {
    this.selectedLang = l;
    localStorage.setItem('currentLang', l);
    window.location.reload();
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    // Check recap for 3 wrong submit
    if(this.activaterecaptcha1 ){
      if (this.captcha == '') {
        $('.captcha').find('[required]').each(function () {
          $(this).parent().find('.form-error-msg').removeAttr('hidden');
        })
        return;
      }else{
        if(!this.verifyrecaptcha1() ){
          return;
        }
      } 
    }
    this.submitted = false;
    this.authservice.getforgetPassword(this.registerForm.value['email']).subscribe(result => {
      if (result['message'] == 'invalid') {
        this.wrongsubmit1 +=1;
        if(this.wrongsubmit1 >= 3 ){
          this.activaterecaptcha1 = true;
        }
        
        if (this.selectedLang == 'fr') {
          this.toaster.error('Adresse mail inconnu, veuillez véfifier votre address.')
          return;
        } else {
          this.toaster.error('Address mail is unknouwn, please check  your address.')
          return;
        }
      }
      this.step1 = false;
      this.step2 = true;
      this.step3 = false;
      this.step4 = false;
      this.code = result['message']
      setTimeout(() => {
        this.router.navigate([''])
      }, 10000)
    }, (err) => {
      this.toaster.show('error while submiting , please reload page')
      return;
    })
  }

  verifyrecaptcha1(){
    let script = this._renderer.createElement('script')
    this._renderer.appendChild(document.body, script);
    this.authservice.verify({ token: this.captcha }).subscribe((res) => {
      if(res['response'] != 'success'){
        return (false)
      }
    });
    return true
  }

  verifyrecaptcha2(){
    let script = this._renderer.createElement('script')
    this._renderer.appendChild(document.body, script);
    this.authservice.verify({ token: this.captcha2 }).subscribe((res) => {
      if(res['response'] != 'success'){
        return (false)
      }
    });
    return true
  }

  checkLink(token, id) {
    // get link
    if (token && id) {
      this.authservice.checklinkactivation(token, id).subscribe((res) => {
        if (res['message'] == 'invalid link') {
          this.toaster.error(this.translate.instant('Error_pwd_4'));
          this.router.navigate(['']);
          return;
        }
        if (res['message'] == 'Link expired') {
          this.toaster.error(this.translate.instant('Error_pwd_5'));
          this.router.navigate(['']);
          return;
        }
        return;
      })

    } else {
      this.toaster.error(this.translate.instant('Error_pwd_6'));
      this.router.navigate(['']);
    }
    // link exist
    // link does not exist
  }

  checkcode() {
    if(this.activaterecaptcha2){
      if (this.captcha2 == '') {
        $('.captcha2').find('[required]').each(function () {
          $(this).parent().find('.form-error-msg').removeAttr('hidden');
        })
        return;
      }else{
        if(!this.verifyrecaptcha2() ){
          return;
        }
      } 
    }
    var code = $('#code').val();
    this.authservice.checkcodeverification(this.route.snapshot.queryParams['token'], this.route.snapshot.queryParams['id'], code).subscribe((resulalt) => {
      if (resulalt['message'] == "Code does not match with used link") {
        
        this.wrongsubmit2 +=1;
        if(this.wrongsubmit2 >= 3){
          this.activaterecaptcha2 = true;
        }
        this.codeverification = false;
      } else {
        this.codeverification = true;
        this.step1 = false;
        this.step2 = false;
        this.step3 = true;
        this.step4 = false;
      }
    })
  }


  SendFormpassword() {
    this.submitted2 = true;
    if (this.changingForm.invalid) {
      return;
    }
    this.submitted2 = false;
    if (this.changingForm.controls['password'].status == 'VALID' && this.changingForm.controls['confirmat'].status == 'VALID') {
      this.matchPassword();
      if (this.match == false) {
        return;
      }
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '25%';

    this.authservice.resetpassword(this.route.snapshot.queryParams['id'], { password: this.changingForm.controls['password'].value }).subscribe((changedpassword) => {
      if(this.selectedLang && this.selectedLang == 'fr'){
        dialogConfig.data = {
          title: "Réinitialiser le mot de passe",
          message: "Le mot de passe a été modifié avec succès",
          choise: ["Fermer"],
        };

      }else{
        dialogConfig.data = {
          title: "Reset password",
          message: "Password changed successfully",
          choise: ["Close"],
        };
      }
      
      const alertbox = this.dialog.open(AlertModuleComponent, dialogConfig);
      alertbox.afterClosed().subscribe(res => {
        this.router.navigate(['']);
      })
    }, (err) => {
      return;
    })
  }

  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
  }

  resolved2(captchaResponse: string) {
    this.captcha2 = captchaResponse;
  }

}
