import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
    /**
     * StorageService is used for localState Management
     *
      * Token of the loggedUser
     *
    **/

  static USER_TOKEN_KEY: string = 'access';
  static USER_ACTIVE_PREVIOUS_SESSION: string = 'UCA';

  constructor() { }

  write(key: string, value: any) {
    if (value) {
      this.remove(key);
      value = JSON.stringify(value);
      localStorage.setItem(key, value);
    }
  }

  read<T>(key: string): T | null {
    const value: string|null = localStorage.getItem(key);

    if (value && value !== 'undefined' && value !== 'null') {
      return <T>JSON.parse(value);
    }

    return null;
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  removeAll() {
    localStorage.clear();
  }

  isExist(key: string) {
    return localStorage.getItem(key) != null;
  }
}
