import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable, ReplaySubject} from 'rxjs';
import {User} from 'src/app/models/user';
import {Token} from 'src/app/models/token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * AuthService is used for API related to users management:
   *
   * Login
   * Create Accounts (Admins)
   * Update Accesses (Admins)
   * Update profile (LoggedUser)
   * Forget Password
   *
   *
   */


  static userSubject: ReplaySubject<User | null> = new ReplaySubject(1);

  constructor(private http: HttpClient) {
  }

  set user(user: User | null) {

    AuthService.userSubject.next(user);
  }

  get user$(): Observable<User | null> {
    return AuthService.userSubject.asObservable();
  }

  login(data: any) {
    return this.http.post<Token>(environment.baseUrl + 'auth/login/', data);
  }

  verify(data){
    return this.http.post<Token>(environment.baseUrl + 'auth/verify/', data);
  }

  getLoggedUser() {
    return this.http.post<User>(environment.baseUrl + 'auth/me/', {});
  }

  register(user: any) {
    return this.http.post(environment.baseUrl + 'auth/register/', user);
  }

  autologin(){
    return this.http.get<User>(environment.baseUrl + 'auth/autologin');
  }


  /** ROUTE FOR FORGET PASSWORD */
  getforgetPassword(email){
    return this.http.get(environment.baseUrl + `auth/getuserbyemail/${email}`,email);
  }

  checklinkactivation(token,id){
    return this.http.get(environment.baseUrl + `auth/getlink/${token}/${id}`);
  }

  checkcodeverification(token:any,id:any,code:any){
    return this.http.get(environment.baseUrl + `auth/verifycodewithtoken/${token}/${id}/${code}`);
  }

  resetpassword(id, password){
    return this.http.put(environment.baseUrl + `auth/resetpassword/${id}`, password);
  }

}
