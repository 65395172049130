import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm, FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';
import {Company, CompanyRequest} from '../../models/company';
import {CompanyService} from '../../services/company.service';
import {User, UserRequest} from '../../models/user';
import {UserService} from '../../services/user.service';
import {PrivilegeService} from '../../services/privilege.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {AlertModuleComponent} from '../shared/alert-module/alert-module.component';
import { ToastrService } from 'ngx-toastr';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { AuthService } from '../../services/auth.service';
import { environment } from 'src/environments/environment';






@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  keyrecaptcha = environment.recaptchaKeyPublic;
  captcha: string;


  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  // TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom
  ];

	
  step : any = 1;
  first_name: any;
  last_name: any;
  cin: any;
  genre:any;
  phonenumber: any;
  email: any;
  birth_date: any;
  name: any;
  logo: any;
  domain: any;
  tax: any;
  registre:any;
  CompanyAdress: any;
  country: any;
  userData = {};
  types : [];
  company: CompanyRequest = new CompanyRequest();
  user: UserRequest = new UserRequest();
  logo_name: any;
  privilege : any;
  today = new Date().toISOString().substring(0,10);;


  constructor(
    private authService: AuthService,
    private router: Router,
    private companyService : CompanyService,
    private toastr: ToastrService,
    private _renderer: Renderer2,
    private userService :  UserService,
    private privilegeService : PrivilegeService,
    private dialog: MatDialog
  ) { 
    this.captcha = '';
    this.email = 'mouhamed.charfii@gmail.com';
  }

  ngOnInit(): void {
    this.getPrivilegesList();
    this.companyService
    .getListTypes()
    .subscribe((resp) => {
      this.types = resp['types'];
    });

  }

  onSubmit(form: NgForm){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '20%';
    // console.log(form.valid)
    if(form.valid){
      if(!($('#logo').prop('files')[0])){
        $('#logo').addClass('error-field');
        $('#logo').parent().find('.form-error-msg').removeAttr('hidden');
        return;
      }
      if (this.captcha == '') {
        $('.captcha').find('[required]').each(function () {
          $(this).parent().find('.form-error-msg').removeAttr('hidden');
        })

      }else{
        let script = this._renderer.createElement('script')
        // script.defer = true;
        // script.async = true;
        // script.src = "https://www.google.com/recaptcha/api.js";
        this._renderer.appendChild(document.body, script);
        this.authService.verify({ token: this.captcha }).subscribe((res) => {
          if (res['response'] == 'success') {
      
      this.clearAll();

      this.logo_name = $('#logo').prop('files')[0];
      this.name = $('#name').val();
      this.logo = this.logo_name['name'];
      this.domain = $('#domain').val();
      this.tax = $('#tax').val();
      this.registre = $('#regiter').val();
      this.CompanyAdress = $('#CompanyAdress').val();
      this.country = $('#country').val();
      var Company = new FormData();
      Company.append('name', this.name);
      Company.append('domain', this.domain);
      Company.append('Lanced_date', this.formatDate(new Date));
      Company.append('tax_ID', this.tax);
      Company.append('commercial_register', this.registre);
      Company.append('logo', this.logo);
      Company.append('file', this.logo_name);
      Company.append('address[street]', this.CompanyAdress);
      Company.append('address[city]', this.country);
  
      this.userService.createUser(this.userData).subscribe((res) =>{
       Company.append('owner_id',res['_id']);
       this.companyService.addCompany(this.name,Company).subscribe((newComp) => {
        dialogConfig.data = {
          title: 'Company request Creation',
          message:'your request has been sent',
          choise:['Close'],
        }
        const alertbox=  this.dialog.open(AlertModuleComponent, dialogConfig);
        alertbox.afterClosed().subscribe(end => {
          var data = {
            'ownerFirstName': this.first_name,
            'ownerLastName':this.last_name,
            'companyName': this.name,
            'etat': 'recieved',
          }
          this.companyService.sendmail(data).subscribe(mail =>{
            this.router.navigate(['/']);          
          })
          
        });
      }, (err) =>{
        alert('Error While creating company'+ err['error'])
      });}, (err) =>{

        if (err.status == 404){
          this.toastr.error('Company request', 'Email already used');
      }
        if(err.status == 400){
          this.toastr.error('Company request', 'Please enter a valid e-mail ...');
        }
      })
    } else {
      return;
    }
  })}
    }else{
      $('#informationCompanyRequest').find('[required]').each(function(){
        
        if (($(this).val() == '') || (!$(this).val())) {
            $(this).addClass('error-field');
            $(this).parent().find('.form-error-msg').removeAttr('hidden');
        }else{
            $(this).removeClass('error-field');
            $(this).parent().find('.form-error-msg').attr('hidden', 'true');
        }})
    }
  }

  Previous(){
    if((this.birth_date)){
      $('#birth_date').val( (this.birth_date).toString());
    }
    this.step -= 1;
   
  }

  Next(){
    var z = 7;
    if(this.user.phone_number){
      z = z-1;
      $('#phonenumber').removeClass('error-field');
      $('#phonenumber').parent().find('.form-error-msg').attr('hidden', 'true');
    }else{
      $('#phonenumber').addClass('error-field');
      $('#phonenumber').parent().find('.form-error-msg').removeAttr('hidden');
    }
    $('#informationCompanyRequest').find('[required]').each(function(){
        
      if (($(this).val() == '') || (!$(this).val())) {
          $(this).addClass('error-field');
          $(this).parent().find('.form-error-msg').removeAttr('hidden');
        }else{
          $(this).removeClass('error-field');
          $(this).parent().find('.form-error-msg').attr('hidden', 'true');
          z = z- 1;

      }})
     if(z < 1 )
      {
        let regex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$');
        if(regex.test(($('#email').val()).toString()) == false){
          this.toastr.error("Company request", "Mail format invalide")
          return;
        }


    this.first_name = $('#first_name').val();
    this.last_name = $('#last_name').val();
    this.cin = $('#cin').val();
    this.phonenumber = $('#phonenumber').val();
    this.email = $('#email').val();
    this.birth_date = $('#birth_date').val();
    this.genre = $('#genre').val();
    let User = {
      "first_name":this.first_name,
      "last_name":this.last_name,
      "cin":this.cin,
      'phone_number':this.user.phone_number['internationalNumber'],
      "email":this.email,
      'birth_date':this.birth_date,
      'genre':this.genre,
      'password' : "Vc0123456",
      'is_active' : false,
      'privilege_id': this.privilege,
    }
    this.userData = User;
    this.step += 1;
  }
  else{
    return;
  }
  }

  // Clear Form  fields
  clearAll(){
    $('#informationCompanyRequest').find('[required]').each(function(){
        $(this).removeClass('error-field');
        $(this).parent().find('.form-error-msg').attr('hidden', 'true');
    })
  }

  formatDate(date: Date) {
    return moment(date).format('YYYY-MM-DD');
  }

  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
  }
  //======================= Get the priviliges for dropdown list display ========================================//
  getPrivilegesList(){
    this.privilegeService.getPrivilegesListsignUp().subscribe((res) => {
      for(var  i  in res){
        if(res[i]['name'] == 'Admin'){
          this.privilege = res[i]['_id'];
          return;
        }
      }
    })
  }
  
}
