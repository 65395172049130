import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrivilegeService {

  // This class will handle the display list 
  // of privileges in the user creation for example.

  constructor(private httpClient: HttpClient) { }

  getPrivilegesList(){
    
    return this.httpClient.get(`${environment.baseUrl}users/privileges`);
  }

  getPrivilegesListsignUp(){
    
    return this.httpClient.get(`${environment.baseUrl}users/new-privileges/for-creating`);
  }
}
