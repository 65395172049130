import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {environment} from '../../environments/environment';


@Injectable({
    providedIn: 'root'
  })
  export class CompanyService {

    constructor(private http: HttpClient) {
    }

    getCompanybyHost(host){
      return this.http.get(`${environment.baseUrl}company/host/${host}`);

    }

    getListTypes() {
        return this.http.get(`${environment.baseUrl}company/types`);
      }
    addCompany(companyname,data){
        return this.http.post(`${environment.baseUrl}company/`,data)
    }

    updateCompany(id: string, data){
      return this.http.put(`${environment.baseUrl}company/update/${id}`,data);
    }
    ListCompanies(offset: number = 0, search = '', limit = 10, loggedUser, me){
      if(loggedUser.is_superuser == false)     
      {
        if(me == false){
          alert('not autorized')
          return;
        }else{
          return this.http.get(`${environment.baseUrl}company/owner/${loggedUser._id}`);
        }
       

      }else{
        if(me ==true){
          return this.http.get(`${environment.baseUrl}company/owner/${loggedUser._id}`);

        }else{
          return this.http.get(`${environment.baseUrl}company/?offset=${offset}&search=${search}&limit=${limit}`);

        }
        
      }
    }

    ListCompaniesNotAproved(){
      return this.http.get(`${environment.baseUrl}company/request`);
    }
    CompanyDetails(id){
      return this.http.get(`${environment.baseUrl}company/${id}`)
    }
    updateEmployeeCompany(id: string, data){
      return this.http.put(`${environment.baseUrl}company/updateemployer/${id}`,data)
    }
    updateAccountantCompnay(id: string, data){
      return this.http.put(`${environment.baseUrl}company/updateaccountant/${id}`,data)
    } 
    
    deleteCompanyRequest(id:string){
      return this.http.delete(`${environment.baseUrl}company/delete-request/${id}`);
    }

    sendmail(data){
      return this.http.put(`${environment.baseUrl}company/sending`,data)
    }

    blockCompany(id:string,data){
      return this.http.put(`${environment.baseUrl}company/block-company/${id}`, data);
    }

    hideCompany(id:string,data){
      return this.http.put(`${environment.baseUrl}company/hide-company/${id}`, data);
    }
    countCompaniesprooved(){
      return this.http.get(`${environment.baseUrl}company/count/approoved/company`)
    }

  }