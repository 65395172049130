import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Subject } from 'rxjs';

@Injectable()
export class DataService {

    _foo: number = 0;

    _fooSource: Subject<number> = new Subject();
  
    get fooSource(): Subject<number> {
      return this._fooSource;
    }
  
    set fooSource(src: Subject<number>) {
      this._fooSource = src;
    }
    constructor(private _http: HttpClient) {}
    changeFoo(n: number) {
        this.fooSource.next(n);
      }
}