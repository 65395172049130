import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Notification, NotificationRequest} from '../models/notification';

@Injectable({
    providedIn: 'root'
  })
export class NotificationService {
    constructor(private http: HttpClient) {
    }
    NotificationListLimited(user:string){
        return this.http.get(`${environment.baseUrl}notifications/notification-last-five?user=${user}`);
      }
    
    notificationRead(id:any){
      return this.http.put(`${environment.baseUrl}notifications/read-notification/${id}`, id)
    }
    notificationUnRead(id:any){
      return this.http.put(`${environment.baseUrl}notifications/non-read-notification/${id}`, id)
    }

    notificationReadAll(id:any){
      return this.http.put(`${environment.baseUrl}notifications/read-notification/user/${id}`, id)
    }
  
}