import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule,  LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FullLayoutComponent } from './full-layout/full-layout.component';
import { LoginComponent } from './login/login.component';
import { NavbarComponent } from './full-layout/navbar/navbar.component';
import { FooterComponent } from './full-layout/footer/footer.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpconfigInterceptor } from './interceptor/httpconfig.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import localePt from '@angular/common/locales/pt';
import { CommonModule, registerLocaleData } from '@angular/common';
import { ErrorComponent } from './full-layout/error/error.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';

import { SharedModule } from './modules/shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { AlertModuleComponent } from './modules/shared/alert-module/alert-module.component';
import { SignUpComponent } from './modules/sign-up/sign-up.component';
import { ToastrModule } from 'ngx-toastr';
import  { RecaptchaModule,RecaptchaFormsModule  } from 'ng-recaptcha';
import { AccountConfirmationComponent } from './modules/account-confirmation/account-confirmation.component';
import { CookieService } from 'ngx-cookie-service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataService} from './data.service'

import * as io  from 'socket.io-client';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';






// Register the localization
// registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    LoginComponent,
    NavbarComponent,
    FooterComponent,
    ErrorComponent,
    SignUpComponent,
    AccountConfirmationComponent,
    ForgetPasswordComponent,
  ],
  imports: [
    
    AngularEditorModule,
    BrowserModule,
    NgSelectModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatDialogModule,

    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
    MatAutocompleteModule,
    MatOptionModule,
    CommonModule,
    NgxIntlTelInputModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TooltipModule.forRoot()
  ],
  exports: [
    MatDialogModule,
  ],
  providers: [
    DataService,
    CookieService,
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpconfigInterceptor,
      multi: true,
  },
  // {
  //   provide: LOCALE_ID,
  //   useValue: 'ar-AR'
  //  },
  //  {
  //    provide: DEFAULT_CURRENCY_CODE,
  //    useValue: 'GBP'
  //  },
],
  bootstrap: [AppComponent],
  entryComponents: [AlertModuleComponent],
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

