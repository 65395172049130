<!-- <div class="alert-overlay"></div> -->
<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content [innerHtml]=" data.message ">{{data.message}}</mat-dialog-content>
<a *ngIf="data.field == true">
    <span><strong>Reason for refusal</strong></span>
    <br><br>
    <textarea  [(ngModel)]="data.text" id="field" type="text" style="width: 100%; height: 95px; border-color: darkgrey;" rows="5" cols="33"></textarea>
</a>
<mat-dialog-actions class="alert-box-action justify-content-end">
    <div *ngFor="let j  of  data.choise ">
        <button *ngIf="data.field == true"   mat-button  mat-dialog-close= {{j}} type="button" class="btn btn-primary box" style="margin-left: 10px; " >{{j}}</button>
        <button  *ngIf="!data.field" mat-button  mat-dialog-close= {{j}} type="button" class="btn btn-primary" style="margin-left: 10px; ;" >{{j}}</button>        
    </div>
</mat-dialog-actions>
