export class Credential {
  /** Credential class
   *
     * Used to handle user input in login Form
   *
   */
  email: string;
  password: string;

  constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
  }

}

