import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { CalendarOptions } from '@fullcalendar/angular'; // Useful for typechecking
import frLocale from '@fullcalendar/core/locales/fr';
import enLocale from '@fullcalendar/core/locales/en-gb';
import {CalendarConfig} from '../../../models/calendar-config';
import { Output, EventEmitter, Inject } from '@angular/core';



declare var $: any;

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})

export class CalendarComponent implements OnChanges {
  @Input() configuration: CalendarConfig;
  @Output() dataEmitter = new EventEmitter<any>();
  calendarOptions: CalendarOptions = {};
  ColorCode:{};
  evenement: any;

  msg:string='';

  eventList = [
  ];
  constructor(


  ) {}

  ngOnChanges(): void {
    this.initCalendar();

  }

  initCalendar() {
    this.configuration.ColorCode;
    
    this.calendarOptions = {
      height: 550,
      initialView: 'dayGridMonth',
      weekends: true,
      weekNumbers: true,
      locales: [enLocale, frLocale],
      locale: enLocale,
      themeSystem: 'bootstrap',
      headerToolbar: {
        start: 'title',
        center: 'timeGridDay,dayGridWeek,dayGridMonth',
        end: 'today prev,next',
      },
      selectable: !this.configuration.readonly,
      

      // editable: true,
      // eventStartEditable: true,
      // eventResizableFromStart: true,
      // eventDurationEditable: true,
      // dateClick: this.handleDateClick.bind(this), // bind is important!

      events: this.configuration.events,
  
      select: (dateInfo) =>  {
        this.emitData(dateInfo);
      },
      eventMouseEnter: function(dateInfo) {
        this.msg = dateInfo['event']['_def']['title'];
        $("#evenement").val(this.msg);

        $("evenement").popover()
        
        this.evenement = this.msg;
      },
      eventMouseLeave:function(){
        $("#evenement").val("");
      },
      

    
    };
  }


  emitData(value: any) {
    this.dataEmitter.emit(value);
    }
  toggleWeekends() {
    this.calendarOptions.weekends = !this.calendarOptions.weekends; // toggle the boolean!
  }

  handleDateClick(arg) {
    alert('date click! ' + arg.dateStr);
  }


}
